/**
 * Handles the upload on a file as soon as the file gets picked,
 * dispatching events as the upload progresses
 * @module lib/file_upload_on_change
 */

// CSS Selector for the input itself
const SELECTOR = '.js-file-upload-on-change';
// CSS Selector for the hidden field that will get the ID returned by the server
export const SELECTOR_HIDDEN_FIELD = '.js-file-upload-on-change__attachment_id';

// The URL to which upload the file
const ATTR_UPLOAD_URL = 'data-file-upload-url';

export const EVENT_START = 'file-upload:start';
export const EVENT_SUCCESS = 'file-upload:success';
export const EVENT_PROGRESS = 'file-upload:progress';
export const EVENT_UPLOADED = 'file-upload:uploaded';
export const EVENT_ERROR = 'file-upload:error';
export const EVENT_COMPLETE = 'file-upload:complete';

export default function setupFileUploadOnChange() {
  $(document).on('change', SELECTOR, ({ target }) => {
    // We only want to upload if there are files set already
    if (target.files.length) {
      // Submit the field
      const data = new FormData();
      data.append('video', target.files[0]);
      let xhr;
      $.ajax({
        // A little bit of gymnastic to get the upload progress
        // visible from jQuery's request
        xhr() {
          xhr = $.ajaxSettings.xhr();
          xhr.upload.addEventListener(
            'progress',
            ({ lengthComputable, loaded, total }) => {
              if (lengthComputable) {
                const progressPercent = (100 * loaded) / total;
                $(target).trigger(EVENT_PROGRESS, progressPercent, xhr);
                if (progressPercent == 100) {
                  $(target).trigger(EVENT_UPLOADED, xhr);
                }
              }
            }
          );
          $(target).trigger(EVENT_START, xhr);
          return xhr;
        },
        url: target.getAttribute(ATTR_UPLOAD_URL),
        data,
        processData: false,
        contentType: false,
        type: 'POST',
        success: data => {
          const $attachmentField = findAttachmentField(target);
          // Backup only the original one in case of multiple replacements
          if (!$attachmentField.attr('data-value')) {
            $attachmentField.attr('data-value', $attachmentField.attr('value'));
          }
          $attachmentField.prop('value', data.video_uuid);
          $(target).trigger(EVENT_SUCCESS, xhr);
        },
        error: () => {
          $(target).trigger(EVENT_ERROR, xhr);
        }
      });
    } else {
      const $attachmentField = findAttachmentField(target);
      $attachmentField.attr('value', $attachmentField.attr('data-value') || '');
    }
  });

  // Disables the field before submit so it doesn't get sent
  // alongside the form
  $(document).on('submit ajax:before', ({ target }) => {
    $(SELECTOR, target).prop('disabled', true);
  });

  // Re-enable the field after the upload ajax finishes
  // in case of errors
  $(document).on('ajax:complete', ({ target }) => {
    $(SELECTOR, target).prop('disabled', false);
  });
}

function findAttachmentField(fileInput) {
  return $(fileInput)
    .closest('fieldset')
    .find(SELECTOR_HIDDEN_FIELD);
}
