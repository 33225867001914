/**
 * Complements the `lib/file_upload` module to add a progress bar
 * while a file is uploading.
 * See the `app/views/shared/forms/_file_upload.html.haml` partial
 * for the HTML supporting it and the `app/webpack/styles/components/_file_upload.scss` SCSS file for the styling
 * @module lib/file_upload_progress
 */

import {
  EVENT_START,
  EVENT_SUCCESS,
  EVENT_ERROR,
  EVENT_PROGRESS,
  EVENT_UPLOADED
} from './file_upload_on_change';
// Re-use the SELECTOR from the file_upload module
import { SELECTOR } from './file_upload';

/** CSS selector for the progress bar */
const SELECTOR_PROGRESS = '.js-file-upload__progress';
/** CSS selector for the status text */
const SELECTOR_STATUS = '.js-file-upload__status';
/** CSS selector for the cancle button */
const SELECTOR_CANCEL = '.js-file-upload__cancel-upload';

const CLASS_UPLOADING = 'wrp-file-upload--is-uploading';
const CLASS_HAS_ERRORS = 'wrp-file-upload--has-errors';

export default function setupFileUploadProgress() {
  $(document).on(EVENT_START, SELECTOR, (event, xhr) => {
    startUpload($(event.currentTarget), xhr, $(event.target));
  });
  $(document).on(EVENT_PROGRESS, SELECTOR, (event, progressPercent) => {
    updateProgress($(event.currentTarget), progressPercent);
  });
  $(document).on(EVENT_UPLOADED, SELECTOR, event => {
    updateStatus($(event.currentTarget));
  });
  $(document).on(EVENT_SUCCESS, SELECTOR, event => {
    endUpload($(event.currentTarget));
  });
  $(document).on(EVENT_ERROR, SELECTOR, (event, xhr) => {
    handleError($(event.currentTarget), xhr);
  });
}

function startUpload($fileUploader, xhr, $input) {
  $fileUploader.removeClass(CLASS_HAS_ERRORS);
  $fileUploader.addClass(CLASS_UPLOADING);
  $fileUploader.find(SELECTOR_PROGRESS).css('width', `0%`);
  $fileUploader.find(SELECTOR_STATUS).text('Uploading');
  $fileUploader.find(SELECTOR_PROGRESS).removeClass('progress-bar-animated');
  $fileUploader.find(SELECTOR_CANCEL).one('click', () => {
    xhr.abort();
    $fileUploader.removeClass(CLASS_UPLOADING);
    $input.val(null);
    $input.trigger('change');
    removeError($fileUploader);
  });
}

function updateProgress($fileUploader, progressPercent) {
  const $progress = $fileUploader.find(SELECTOR_PROGRESS);
  $progress.css('width', `${progressPercent}%`);
}

function updateStatus($fileUploader) {
  $fileUploader.find(SELECTOR_STATUS).text('Processing your upload');
  $fileUploader.find(SELECTOR_PROGRESS).addClass('progress-bar-animated');
}

function endUpload($fileUploader) {
  $fileUploader.removeClass(CLASS_UPLOADING);
  removeError($fileUploader);
}

function handleError($fileUploader, xhr) {
  $fileUploader.find(SELECTOR_STATUS).text('Sorry, the upload failed');
  $fileUploader.find(SELECTOR_PROGRESS).removeClass('progress-bar-animated');
  $fileUploader.addClass(CLASS_HAS_ERRORS);
  removeError($fileUploader);
  // Only render our own errors;
  if (xhr.status == 422) {
    try {
      const errors = JSON.parse(xhr.response);
      if (errors.message) {
        renderError($fileUploader, errors.message);
      }
    } catch {
      /* Just don't stop the JS execution */
    }
  }
}

function removeError($fileUploader) {
  $fileUploader
    .closest('.form-group')
    .find('.form-group.file .invalid-feedback')
    .remove();
}

function renderError($fileUploader, message) {
  if (message) {
    $fileUploader
      .closest('.form-group')
      .find('.form-group:first')
      .append(`<p class="invalid-feedback d-block">${message}</p>`);
  }
}
