/**
 * Add tracking of cocoon fields position
 * into an actual form field to allow storage
 * of the field positions using `act_as_list`
 * on the backend
 * @module lib/cocoon_position
 */
import { SELECTOR_FIELDS, markDeleted } from '.';

const SELECTOR = '.js-cocoon-position';

const SELECTOR_POSITION_FIELD = '.js-cocoon-reorderable__position-input';

export default function setupCocoonPosition() {
  $(document).on('cocoon:after-insert', SELECTOR, ({ target }) => {
    updatePositions(target);
  });

  $(document).on('cocoon:after-remove', SELECTOR, ({ target }, [field]) => {
    markDeleted(field);
    updatePositions(target);
  });
}

export function updatePositions(fieldsWrapper) {
  const fields = [...fieldsWrapper.querySelectorAll(SELECTOR_FIELDS)];

  fields.forEach((field, index) => {
    const input = field.querySelector(SELECTOR_POSITION_FIELD);
    input.value = index + 1;
    // Dispatch a synthetic `input` event
    // to notify that the input's value changed
    const event = new CustomEvent('input', { bubbles: true });
    input.dispatchEvent(event);
  });
}
