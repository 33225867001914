/**
 * Setup of the SimpleMDE editor and its binding to FountainJS
 * @module lib/script_editor
 */
import SimpleMDE from 'simplemde/src/js/simplemde';

import { EVENT_WILL_INSERT } from './dom';

const SELECTOR = '.js-script-editor';

const ATTR_ISLAND_LABEL = 'data-island-label';

// Attempt at using fountain to compile the markdown
import fountain from '../../vendor/fountainjs/fountain.js';
SimpleMDE.prototype.markdown = function(text) {
  let script;
  let title;
  fountain.parse(text, function(output) {
    title = output.html.title_page || '';
    // Fountain returns `<p>undefined</p>` if the input string is empty
    script = text ? output.html.script : '';
  });
  return `
    <div id="workspace">
      <div id="script" class="us-letter dpi100">
        ${title && `<div class="page title-page">${title}</div>`}
        <div class="page">
          ${script}
        </div>
      </div>
    </div>
  `;
};

export function setupScriptEditor() {
  $(document).on('turbolinks:load', () => setup());
  $(document).on(EVENT_WILL_INSERT, ({ detail }) => {
    setup(detail.html);
  });
  $(document).on('cocoon:before-insert', (e, [tabpanel]) => {
    setup(tabpanel);
  });

  $(document).on('shown.bs.tab', '[id^=tab_variant_]', ({ target }) => {
    $(`#${target.getAttribute('aria-controls')}`)
      .find(SELECTOR)
      .each((index, el) => {
        el.__editor.codemirror.refresh();
      });
  });

  setup();
}

function setup(parent = document) {
  $(parent)
    .find(SELECTOR)
    .each((index, el) => {
      const editor = new SimpleMDE({
        element: el,
        status: ['line', 'words'],
        spellChecker: false,
        toolbar: [
          'bold',
          'italic',
          '|',
          'preview',
          'side-by-side',
          'fullscreen'
        ],
        parsingConfig: {
          highlightFormatting: false
        }
      });
      // Cache the editor on the element for easier access
      // when refreshing it when tabs load
      el.__editor = editor;
      setupFullscreenTitle(editor, el);
    });
}

function setupFullscreenTitle(editor, el) {
  editor.codemirror.on('optionChange', function(codemirror, optionName) {
    if (optionName == 'fullScreen') {
      if (codemirror.getOption('fullScreen')) {
        // We need to grab a couple of information from the form
        // as the user might have made edits that we want reflected
        // in the fullscreen title
        const summary = $(editor.element)
          .closest('.js-script-editor-title__variant')
          .find('.js-script-editor-title__summary-field')
          .val();

        const name = $(editor.element)
          .closest('.js-script-editor-title__island')
          .find('.js-script-editor-title__name-field')
          .val();

        // Tempting to read that value when the editor is created
        // but as users can add and remove tabs, we need to do it
        // when fullscreen gets triggered
        const tabPanel = $(editor.element).closest(
          '.js-variant-tabs__tabpanel'
        );
        const tabIndex = [...tabPanel.parent().children('div')].indexOf(
          tabPanel[0]
        );

        $(editor.gui.toolbar)
          .find('.js-script-editor-title__island-name')
          .text(name || 'Untitled island');
        $(editor.gui.toolbar)
          .find('.js-script-editor-title__islandSummary')
          .text(summary || 'No summary');
        $(editor.gui.toolbar)
          .find('.js-script-editor-title__variant-number')
          .text(tabIndex + 1);
      }
    }
  });

  $(
    `<div class="rythm-0 editor-toolbar__info wrp-script-editor-info">
          <p>
            ${el.getAttribute(
              ATTR_ISLAND_LABEL
            )}.<span class="js-script-editor-title__variant-number"></span>:
            <span class="js-script-editor-title__island-name">Island title</span></p>
          <p class="text-muted font-size-smaller js-script-editor-title__islandSummary">Island summary</p>
        </div > `
  ).appendTo(editor.gui.toolbar);
}
