const SELECTOR_ACTIVE_VARIANT_TAB = '.js-variant-tabs__tab.active';

export default function toggleCustomButtonPositionsDetails(promptStyleInput) {
  const activeVariantTabIndex = $(SELECTOR_ACTIVE_VARIANT_TAB).index();

  // Get all the height and width inputs for the active tab variant
  // NOTE: newly created variants do not have a variant index to appended to class,
  // so here we also look for elements without index as a fallback
  const choiceHeightInputs = document.querySelectorAll(`.js-custom-button-height-input-${activeVariantTabIndex}, .js-custom-button-height-input-`);
  const choiceWidthInputs = document.querySelectorAll(`.js-custom-button-width-input-${activeVariantTabIndex}, .js-custom-button-width-input-`);

  // To ensure the link is always disabled for variants that aren't in the database yet,
  // we aren't looking for elements with the class without a variant index
  const customButtonPositionsLink = document.querySelector(`#js-custom-button-positions-editor-link-${activeVariantTabIndex}`);

  if (['custom_button_positions'].includes(promptStyleInput.value)) {
    // Enable choice's form inputs for width and height
    choiceHeightInputs.forEach(function (input) {
      input.disabled = false;
    });

    choiceWidthInputs.forEach(function (input) {
      input.disabled = false;
    });
    console.log('Custom button positions selected');
  } else {
    // Disable choice's form inputs for width and height
    choiceHeightInputs.forEach(function (input) {
      input.disabled = true;
    });

    choiceWidthInputs.forEach(function (input) {
      input.disabled = true;
    });

    // Disable link to edit custom positions
    // NOTE: This link doesn't exist in a project edit form
    if (customButtonPositionsLink) {
      customButtonPositionsLink.classList.add('disabled');
      customButtonPositionsLink.classList.remove('btn--with-arrows');
    }
  }
}
