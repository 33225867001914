import toggleLocationField from './choices/locations';
import toggleCustomButtonPositionsDetails from './choices/custom_button_positions';
import applyTimeValidation from './choices/time_validation';

export default function setupChoicePropertyFieldsAndDetails() {
  $(document).on('change', '.js-prompt-style-field', (e) => {
    const target = e.target;
    console.log('Change to prompt style detected');
    toggleLocationField(target);
    toggleCustomButtonPositionsDetails(target);
  });

  $(document).on('input', '.js-show-hide-at', (e) => {
    const target = e.target;
    console.log('Change detected');
    applyTimeValidation(target);
  });

  // Add more rows when the Add More button is clicked
  $(document).on('click', '[id^="add-show-hide-at-row-"]', function (event) {
    event.preventDefault();

    // Get variant and form indices from the clicked button's data attributes
    const variantIndex = $(this).data('variant-index');
    const formIndex = $(this).data('form-index');

    // Find the dynamic form container for the specific variant and form
    const parentContainer = $(`#dynamic-form-container-${variantIndex}-${formIndex}`);

    // Calculate the current row index based on the number of rows inside the container
    const rowIndex = parentContainer.find('.row').length;

    // Template for the new row
    const newRow = `
      <div class="row mt-2 flex-wrap">
        <div class="col-md-6">
          <label for="show_hide_at_show_${variantIndex}_${formIndex}_${rowIndex}">Show At</label>
          <input name="show_hide_at_show[${variantIndex}][${formIndex}][${rowIndex}]" type="text" placeholder="e.g. 0m5s" class="form-control js-show-hide-at" data-test-id="new-row-show-at-${variantIndex}-${formIndex}-${rowIndex}">
          <div class="invalid-feedback">Use the format 0m5s</div>
        </div>
        <div class="col-md-6">
          <label for="show_hide_at_hide_${variantIndex}_${formIndex}_${rowIndex}">Hide At</label>
          <input name="show_hide_at_hide[${variantIndex}][${formIndex}][${rowIndex}]" type="text" placeholder="e.g. 1m7s" class="form-control js-show-hide-at" data-test-id="new-row-hide-at-${variantIndex}-${formIndex}-${rowIndex}">
          <div class="invalid-feedback">Use the format 1m7s</div>
        </div>
      </div>
    `;

    // Append the new row to the correct dynamic form container
    parentContainer.append(newRow);
  });
}
