/**
 * Manages tabs when cocoon elements are added or removed
 * @module lib/form_tabs
 */
const SELECTOR_ALL_TABPANELS = '.js-tabs__tabpanel';
const SELECTOR_CHOICES_TABLABEL = '.nav-choices';
const SELECTOR_CHOICES_TABPANEL = '.js-tab-content-choices';
const SELECTOR_ADD_CHOICE = '.js-choice-tabs__insert-before';
const SELECTOR_NUMBER = '.js-tab-number';

const ATTR_TAB_INDEX = 'data-tabs-index';
const SELECTOR_TAB = '[role=tab]';

export default function setupFormTabs() {
  $(document).on('cocoon:after-insert', (event, [tabpanel]) => {
    // Get panel type eg. variant, choice
    const type = getTypeFrom(tabpanel);

    if ($(tabpanel).hasClass(`js-${type}-tabs__tabpanel`)) {
      const index = getNextTabIndex(event.target);
      // Add IDs to tab panel
      addIds(tabpanel, index, type);

      // Get the tablist for current type
      const $tabList = $(`.js-${type}-tabs__tablist`);
      // Assign tab label attributes from tab panel
      const tab = addTabForPanel(tabpanel, $tabList, type);

      // Make the new tab visible
      tab.click();

      updateNumbering($tabList);
    }
  });

  $(document).on('cocoon:after-remove', (event, [tabpanel]) => {
    const type = getTypeFrom(tabpanel);
    if ($(tabpanel).hasClass(`js-${type}-tabs__tabpanel`)) {
      const $tabList = $(`.js-${type}-tabs__tablist`);
      removeTabForPanel(tabpanel, $tabList);
      updateNumbering($tabList);
    }
  });
}

function removeTabForPanel(tabpanel, $tablist) {
  const tab = $tablist.find(`[id="${tabpanel.getAttribute('aria-labelledby')}"]`);

  const visibleTab = tab[0].previousElementSibling || tab[0].nextElementSibling;
  if (visibleTab) {
    // Little check for the removal of the last tab
    visibleTab.click();
  }

  tab.remove();
}

function updateNumbering($tablist) {
  // Take advantage that the elements will be returned in the right order
  $tablist.find(SELECTOR_NUMBER).each((index, element) => {
    element.textContent = index + 1;
  });
}

function addIds(tabpanel, index, type) {
  if (type === 'variant') {
    tabpanel.setAttribute('id', `tabpanel_${type}_${index}`);
    tabpanel.setAttribute('aria-labelledby', `tab_${type}_${index}`);

    // Set the variant index as attribute so choices can reference it
    $(SELECTOR_CHOICES_TABLABEL)[index].setAttribute('data-variant-index', index);

    // Get the first choice panel for the new variant
    const firstPanel = $(SELECTOR_CHOICES_TABPANEL).children().last()[0];

    // Give it a variant ID
    firstPanel.setAttribute('id', `tabpanel_variant_${index}_choice_0`);

    // Set variant index for data association insertion
    const addChoiceBtn = $(tabpanel).find(SELECTOR_ADD_CHOICE)[0];
    const targetTabPanel = $(tabpanel).find(SELECTOR_CHOICES_TABPANEL)[0];

    firstPanel.setAttribute('aria-labelledby', `tab_variant_${index}_choice_0`);
    addChoiceBtn.setAttribute('data-association-insertion-node', `.js-cocoon-variant-${index}-choices`);
    $(targetTabPanel).removeClass('js-cocoon-variant--choices').addClass(`js-cocoon-variant-${index}-choices`);

    // Set variant index ids and set as data attributes
    // Targets media select drop down container and a link to create image in Adobe
    $(tabpanel)
      .find('[data-variant-index-id]')
      .each(function () {
        $(this)[0].setAttribute('data-variant-index-id', index);
      });

    // Set unique ids for media select options
    // to be used in media select drop down and options
    const mediaElementsContainerIds = ['embed-container-', 'image-container-', 'video-container-', 'variant-media-video-', 'variant-media-image-', 'variant-media-embed-'];
    $.each(mediaElementsContainerIds, function (i, value) {
      // Embed-container is not rendered at all if the feature flag is off!
      $(tabpanel).find(`#${value}`)[0]?.setAttribute('id', `${value}${index}`);
    });

    // Set unique "for" attributes to the radio (button) labels.
    // Label "for" attribute needs to match the id of the radio (button) input
    // Ids for radio inputs are set above
    const mediaElementLabels = ['variant-media-video', 'variant-media-image', 'variant-media-embed'];
    $.each(mediaElementLabels, function (i, value) {
      $(tabpanel)
        .find(`.${value}`)
        ?.each(function () {
          $(this).attr('for', `${value}-${index}`);
        });
    });

    // Set unique "name" for the radio (button) inputs
    // A group of radio (button) inputs with the same name can have only one input checked,
    // this way each variant has its own group of radio (buttons) with one value preselected by default
    const mediaElementInputs = ['variant-media-radio-button-'];
    $.each(mediaElementInputs, function (i, value) {
      $(tabpanel)
        .find(`#${value}`)
        ?.each(function () {
          $(this).attr('name', `${value}${index}`);
        });
    });
  } else if (type === 'choice') {
    // Get variant index
    const variantIndex = getVariantIndexForChoice(tabpanel);

    tabpanel.setAttribute('id', `tabpanel_variant_${variantIndex}_choice_${index}`);
    tabpanel.setAttribute('aria-labelledby', `tab_variant_${variantIndex}_choice_${index}`);
  }

  tabpanel.setAttribute(ATTR_TAB_INDEX, index);
}

function addTabForPanel(tabpanel, $tablist, type) {
  // Get the new variant index
  const variantIndex = getVariantIndex(tabpanel);
  const newTab = $tablist.find(SELECTOR_TAB)[0].cloneNode(true);

  // Cleanup active markers in case they're there
  $(newTab).removeClass('show active wrp-tab--has-error');

  // Link the tab and its tabpanel
  newTab.setAttribute('data-target', `#${tabpanel.getAttribute('id')}`);
  newTab.setAttribute('id', tabpanel.getAttribute('aria-labelledby'));
  newTab.setAttribute('aria-controls', tabpanel.getAttribute('id'));

  const SELECTOR_INSERT_BEFORE = `.js-${type}-tabs__insert-before`;

  // For new variant, pass the variant index to first choice tab label
  if (type === 'variant') {
    // Get the new choice label
    const firstLabel = $(SELECTOR_CHOICES_TABLABEL).children('button').last()[0];

    firstLabel.setAttribute('id', `tab_variant_${variantIndex}_choice_0`);
    firstLabel.setAttribute('data-target', `#tabpanel_variant_${variantIndex}_choice_0`);

    $tablist.find(SELECTOR_INSERT_BEFORE).before(newTab);
  } else if (type === 'choice') {
    const variantIndexForChoice = getVariantIndexForChoice(tabpanel);

    $($tablist[variantIndexForChoice]).find(SELECTOR_INSERT_BEFORE).before(newTab);
  }

  return newTab;
}

function getNextTabIndex(tabpanelContainer) {
  // Get all tabpanels for current container
  const index = [...$(tabpanelContainer).children(SELECTOR_ALL_TABPANELS)].reduce((max, tabpanelEl) => {
    // Fetch the index of the panel
    const currentIndex = parseInt(tabpanelEl.getAttribute(ATTR_TAB_INDEX), 10);
    console.info('Current tab index', currentIndex, 'larger number', currentIndex > max ? currentIndex : max);
    // Get the larger number
    return currentIndex > max ? currentIndex : max;
  }, -Infinity);

  return index + 1;
}

function getTypeFrom(tabpanel) {
  const tabPanelType = $(tabpanel).hasClass('js-variant-tabs__tabpanel');

  return tabPanelType ? 'variant' : 'choice';
}

function getVariantIndex(tabpanel) {
  const index = $(tabpanel).find('.nav-choices').attr('data-variant-index');

  return index;
}

function getVariantIndexForChoice(tabpanel) {
  const index = $(tabpanel).parent().prev().children()[0].getAttribute('data-variant-index');

  return index;
}
