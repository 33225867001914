const SELECTOR = '.js-plan-variants';
export const SELECTOR_CONTROL = '.js-plan-variants__control';
const SELECTOR_PANEL = '.js-plan-variants__panel';

export default function setupPlanVariants() {
  console.log('Set up');
  $(document).on('change', SELECTOR_CONTROL, event => {
    const panels = $(event.target)
      .closest(SELECTOR)
      .find(SELECTOR_PANEL);
    panels.each(function(index, panel) {
      if (panel.dataset.variantId == event.target.value) {
        panel.classList.remove('d-none');
      } else {
        panel.classList.add('d-none');
      }
    });
  });
}
