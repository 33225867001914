/**
 * Binds the text of a tab to an input within its tabpanel,
 * @module lib/form_tabs_labels
 */
/** CSS selector to mark the tabpanels */
const SELECTOR_CHOICE_TABPANEL = '.js-choice-tabs__tabpanel';
/** CSS selector that marks the input that feeds the tab name */
const SELECTOR_CHOICE_TAB_LABEL_SOURCE = '.js-choice-tabs-labels__source';

const EMPTY_LABEL = '???';

export default function setupFormTabsLabels() {
  $(document).on(
    'input',
    SELECTOR_CHOICE_TABPANEL,
    ({ target, currentTarget }) => {
      if ($(target).is(SELECTOR_CHOICE_TAB_LABEL_SOURCE)) {
        const tab = getTabFor(currentTarget);
        setTabLabel(tab, target);
      }
    }
  );

  // After new tab inserted, set text to empty label
  $(document).on('cocoon:after-insert', (event, [tabpanel]) => {
    if ($(tabpanel).is(SELECTOR_CHOICE_TABPANEL)) {
      const tab = getTabFor(tabpanel);

      const nameInput = tabpanel.querySelector(
        SELECTOR_CHOICE_TAB_LABEL_SOURCE
      );
      if (nameInput) {
        setTabLabel(tab, nameInput);
      }
    }
  });
}

function setTabLabel(tab, nameInput) {
  tab.textContent = nameInput.value || EMPTY_LABEL;
}

function getTabFor(tabpanel) {
  // Relies on the tabpanel being labelled by its tab
  return document.getElementById(tabpanel.getAttribute('aria-labelledby'));
}
