export default function GenericProjectVariableWithinForm(wrapper){
  this.$nonBoolValueInput = $(wrapper).find('.non-boolean-value-input');
  this.$boolValueInput = $(wrapper).find('.boolean-value-select');
}

GenericProjectVariableWithinForm.prototype.varValueTypeWillBe = function(valueType){
  const initMethodName = `handleValueType_${valueType}`;
  this[initMethodName]();
};


GenericProjectVariableWithinForm.prototype.handleValueType_boolean = function(){
  this.useNonBoolInputs(false);
};

GenericProjectVariableWithinForm.prototype.handleValueType_number = function(){
  this.useNonBoolInputs(true);
};

GenericProjectVariableWithinForm.prototype.handleValueType_text = function(){
  this.useNonBoolInputs(true);
};

GenericProjectVariableWithinForm.prototype.useNonBoolInputs = function(value){
  if (value) {
    this.enableInputsFor(this.$nonBoolValueInput);
    this.disableInputsFor(this.$boolValueInput);
  } else {
    this.enableInputsFor(this.$boolValueInput);
    this.disableInputsFor(this.$nonBoolValueInput);
  }
};

GenericProjectVariableWithinForm.prototype.enableInputsFor = function($el){
  $el.removeClass('d-none');
  $el.find('input, select').removeAttr('disabled');
};

GenericProjectVariableWithinForm.prototype.disableInputsFor = function($el){
  $el.addClass('d-none');
  $el.find('input, select').attr('disabled', 'disabled');
};
