/**
 * A module for periodically polling the exports's result
 * @module lib/check_export
 */
const PATH_ATTRIBUTE = 'data-check-export-path';

export default function checkExport() {
  let interval;

  // Expose the function to `window` so that the backend can call it
  window.setUpExportCheck = function() {
    const element = document.querySelector(`[${PATH_ATTRIBUTE}]`);

    if (element) {
      interval = setInterval(() => {
        $.get(element.getAttribute(PATH_ATTRIBUTE));
      }, 1000);
    }
  };

  $(document).on('turbolinks:before-cache', () => {
    clearInterval(interval);
  });

  // TODO: Looks like dead code
  window.notifyExportIsAvailable = function() {
    clearInterval(interval);
  };
}
