import $ from 'jquery';
const SELECTOR = '.js-password-toggle';

$(document).on('click', SELECTOR, ({ target }) => {
  toggle(target.previousSibling);
});

function toggle(input) {
  if (input.type === 'password') {
    input.type = 'text';
  } else {
    input.type = 'password';
  }
}
