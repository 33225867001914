const SELECTOR_ACTIVE_VARIANT_TAB = '.js-variant-tabs__tab.active';

export default function selectFieldsets() {
  const activeVariantTabIndex = $(SELECTOR_ACTIVE_VARIANT_TAB).index();

  const dropdown = document.querySelector(`.form-check-input-radio-${activeVariantTabIndex}`);

  const toggleElements = (showElement, hideElements) => {
    showElement.classList.remove('d-none');
    hideElements.forEach((element) => {
      element.classList.add('d-none');
    });
  };

  $(document).on('change', dropdown, (e) => {
    console.log(e.target);
    console.log(e.target.value);
    const {
      value,
      dataset: { variantIndexId },
    } = e.target;

    const videoContainer = document.querySelector(`#video-container-${variantIndexId}`);
    const imageContainer = document.querySelector(`#image-container-${variantIndexId}`);
    const embedContainer = document.querySelector(`#embed-container-${variantIndexId}`);

    const mediaTypeOperations = {
      video: {
        show: videoContainer,
        hide: [imageContainer, embedContainer],
      },
      image: {
        show: imageContainer,
        hide: [videoContainer, embedContainer],
      },
      embed: {
        show: embedContainer,
        hide: [imageContainer, videoContainer],
      },
    };

    // Pass in the media type based on the target value
    const selectedMediaType = mediaTypeOperations[value];

    if (selectedMediaType) {
      console.log(selectedMediaType, 'selectedMediaType');
      toggleElements(selectedMediaType.show, selectedMediaType.hide);
    }
  });
}
