import isWithin from './is_within';

const SELECTOR = '.js-file-upload-validate';

const SELECTOR_ERROR_BOUNDARY = '.js-file-upload-validate__error-boundary';
const SELECTOR_ERROR_MESSAGE = '.invalid-feedback';
const SELECTOR_ADD_DESCRIPTION = '.js-file-upload-validate__add-description';

const ERROR_POSITION = 'afterbegin';

// 4GB
const MAX_SIZE = 4000 * 1024 * 1024;

export default function setupFileUploadValidation() {
  // Prepare the rendering of the validation errors
  document.addEventListener(
    'change',
    (event) => {
      clearError(event.target);
    },
    true
  );

  document.addEventListener(
    'invalid',
    (event) => {
      displayError(event.target);
    },
    true
  );

  // Validate on the capture phase so we can stop the propagation
  // and prevent any further behaviour from happening
  document.addEventListener(
    'change',
    (event) => {
      if (isWithin(SELECTOR, event.target)) {
        const field = event.target;
        const file = field.files[0];
        if (file && file.size > MAX_SIZE) {
          field.setCustomValidity(
            `Please pick a file less than ${formatFileSize(
              MAX_SIZE
            )}. See <a href="https://www.stornaway.io/help/" class="wrp-link--blended wrp-link--underlined" target="_blank" rel="noreferrer noopener">Help</a> for more details.`
          );
          field.reportValidity();
          // Would be better to preventDefault
          // but the rest of the listeners is not working this way
          event.stopPropagation();
        } else {
          field.setCustomValidity(null);
        }
      }
    },
    true
  );
}

function clearError(field) {
  const boundary = field.closest(SELECTOR_ERROR_BOUNDARY);
  if (!boundary) return;

  const errorMessage = boundary.querySelector(SELECTOR_ERROR_MESSAGE);
  if (errorMessage) {
    errorMessage.parentElement.removeChild(errorMessage);
  }

  const description = boundary.querySelector(SELECTOR_ADD_DESCRIPTION);
  removeDOMToken(description, 'aria-describedby', errorId(field));
  removeDOMToken(field, 'aria-describedby', errorId(field));
}

function displayError(field) {
  const boundary = field.closest(SELECTOR_ERROR_BOUNDARY);

  boundary.insertAdjacentHTML(
    ERROR_POSITION,
    error(field.validationMessage, { id: errorId(field) })
  );

  const description = boundary.querySelector(SELECTOR_ADD_DESCRIPTION);
  prependDOMToken(description, 'aria-describedby', errorId(field));
  prependDOMToken(field, 'aria-describedby', errorId(field));
}

function removeDOMToken(element, attributeName, token) {
  if (!element.getAttribute(attributeName)) return;
  element.setAttribute(
    attributeName,
    element.getAttribute(attributeName).replace(token, '').trim()
  );
}

function prependDOMToken(element, attributeName, token) {
  element.setAttribute(
    attributeName,
    [token, element.getAttribute(attributeName)].filter(Boolean).join(' ')
  );
}

function formatFileSize(sizeInBytes) {
  return `${sizeInBytes / 1024 / 1024}Mb`;
}

function errorId(field) {
  return field.id + '_error';
}

function error(message, { id }) {
  return `<p id="${id}" class="invalid-feedback d-block mb-2 mt-0" aria-live="assertive">${message}</p>`;
}
