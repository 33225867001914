const SELECTOR = '.js-load-modal-content';

export default function setupSidebarLoadingConcurrency() {
  let xhr;
  $(document).on('ajax:beforeSend', SELECTOR, function({ originalEvent }) {
    if (xhr) {
      // Not ideal, but there's no way to distinguish
      // between an aborted request and a request that had a network error
      // through the content of the xhr when it errors :'(
      xhr._wasAborted = true;
      xhr.abort();
    }
    xhr = originalEvent.detail[0];
  });
  $(document).on('ajax:success', SELECTOR, function() {
    xhr = null;
  });
  $(document).on('ajax:error', SELECTOR, function({ originalEvent }) {
    const eventXHR = originalEvent.detail[2];
    // Only consider non aborted requests
    if (!eventXHR._wasAborted) {
      xhr = null;
    }
  });
}
