function disableExportBtn(status) {
  const $exportBtn = $('.require-read-yt-information-page');
  $exportBtn.prop('disabled', status);
}

export default function setupProjectExportPage() {
  const flagName = 'readYTInformationPage';
  const $input = $('input[type=checkbox]#read_youtube_information_page');
  $input.prop('checked', window.localStorage.getItem(flagName));
  $input.on('change', () => {
    if ($input.prop('checked')) {
      window.localStorage.setItem(flagName, true);
      disableExportBtn(false);
    } else {
      window.localStorage.removeItem(flagName);
      disableExportBtn(true);
    }
  });
  $input.trigger('change');
}
