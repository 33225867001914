/**
 * Implementation of keyboard controls for moving focus
 * with arrow keys in a tab component
 * Based on https://glitch.com/~roving-tabindex by Rob Dodson
 * Copyright 2018 Google LLC.
 * SPDX-License-Identifier: Apache-2.0
 * @module lib/roving_tabindex
 */
const SELECTOR = '.js-roving-tabindex';
const SELECTOR_TARGET = 'button,[role="button"]';
const SELECTOR_ACTIVE = '.active';

const KEYCODE = {
  LEFT: 37,
  RIGHT: 39
};

export default function setupRovingTabindex() {
  $(document).on('keydown', SELECTOR, onKeyDown);
  $(document).on('click', SELECTOR, onClick);
  $(document).on('focusout', SELECTOR, onFocusOut);
}

function onFocusOut({ currentTarget }) {
  // During focusout, the focus is reset to the body :(
  // So we need to listen to the next 'focusin' event
  // To see if it is within our container or not
  $(document).one('focusin', ({ target }) => {
    if (!currentTarget.contains(target)) {
      activate(currentTarget.querySelector(SELECTOR_ACTIVE), currentTarget, {
        focus: false
      });
    }
  });
}

function onKeyDown({ keyCode, currentTarget }) {
  switch (keyCode) {
    case KEYCODE.RIGHT:
      event.preventDefault();
      focusNextItem(currentTarget);
      break;
    case KEYCODE.LEFT:
      event.preventDefault();
      focusPreviousItem(currentTarget);
      break;
  }
}

function onClick({ target, currentTarget }) {
  // Make sure the clicked item is one of the buttons and
  // not something random :)
  const buttons = [...currentTarget.querySelectorAll(SELECTOR_TARGET)];
  if (buttons.indexOf(target) == -1) {
    return;
  }
  activate(target, currentTarget);
}

// Figure out if the current element has a next sibling.
// If so, moving focus to it.
function focusNextItem(container) {
  const item = document.activeElement;
  if (item.nextElementSibling) {
    activate(item.nextElementSibling, container);
  } else {
    // Activate first element
    activate(container.querySelector(SELECTOR_TARGET), container);
  }
}

// Figure out if the current element has a previous sibling.
// If so, moving focus to it.
function focusPreviousItem(container) {
  const item = document.activeElement;
  if (item.previousElementSibling) {
    activate(item.previousElementSibling, container);
  } else {
    activate(last(container.querySelectorAll(SELECTOR_TARGET)), container);
  }
}

// This is where the roving tabindex magic happens!
function activate(item, container, { focus = true } = {}) {
  // Set all of the buttons to tabindex -1
  container
    .querySelectorAll(SELECTOR_TARGET)
    .forEach(btn => (btn.tabIndex = -1));

  // Make the current button "active"
  item.tabIndex = 0;
  if (focus) {
    item.focus();
  }
}

function last(array) {
  return array[array.length - 1];
}
