/**
 * Adds confirmation for actions that would close the form
 * making users lose the edits they have already made
 * @module lib/confirm_out_of_form_navigation
 */
import { EVENT_WILL_INSERT } from './dom';
import isWithin from './is_within';

/** CSS selector for the form on which to track the changes */
const SELECTOR_FORM = '.js-confirm-out-of-form-navigation__form';
/** CSS selector for a unique element whose events (click, drawer:will-close) should trigger confirmation */
const SELECTOR_TRIGGER = '.js-confirm-out-of-form-navigation__trigger';
/** CSS selector for an element inside which events  within it should strigger confirmation */
// TODO: See if it can be merged to SELECTOR_TRIGGER as `.js-confirm-out-of-form-navigation__area *`
const SELECTOR_AREA = '.js-confirm-out-of-form-navigation__area';

const MESSAGE = `
Stop!

Click Cancel to go back and save your changes.

If you click OK, you will lose your unsaved changes.
`;

/**
 * Tracks the modifications in the form and prompts for confirmation
 * when selected links/buttons (or links/buttons in selected areas)
 * are activated
 */
export default function setupConfirmOutOfFormNavigation() {
  let formHasChanges = false;
  // We want to know as soon as user input any data
  // as they can press "Escape" to close the modal
  // while focused on a field
  $(document).on('input', SELECTOR_FORM, () => {
    formHasChanges = true;
  });
  // Adding/removing variants and choices is another form
  // of editing the form
  $(document).on('cocoon:after-insert', () => {
    formHasChanges = true;
  });
  $(document).on('cocoon:after-remove', () => {
    formHasChanges = true;
  });
  // Reset when the form gets replaced
  $(document).on(EVENT_WILL_INSERT, SELECTOR_FORM, () => {
    formHasChanges = false;
  });
  document.addEventListener(
    'ajax:success',
    event => {
      if (isWithin(SELECTOR_FORM, event.target)) {
        formHasChanges = false;
      }
    },
    true
  );
  // Use capture to make sure the check happens before anything
  // and can prevent any other behaviour
  document.addEventListener('click', maybePreventAction, true);
  // TODO: Use capture for this event as well
  $(document).on('drawer:will-close', maybePreventAction);
  // Used by island highlighting to know if it can do it or not
  document.addEventListener(
    'studio:cell:pointerclick',
    maybePreventAction,
    true
  );

  // Because of how JointJS handles its events, `studio:cell:pointerclick`
  // will happen during the same interaction as `click` due to being triggered
  // by the `mouseup` event corresponding to that click.
  // In that scenario, we:
  // - only want to ask once
  // - but cancel the click following the interaction
  let alreadyAskedDuringInteraction;
  let prevent;

  function maybePreventAction(event) {
    if (
      // Only consider actions that should actually target the confirmation
      isWithin(SELECTOR_AREA, event.target) ||
      $(event.target).is(SELECTOR_TRIGGER)
    ) {
      if (formHasChanges) {
        // Only ask if we haven't asked already
        if (!alreadyAskedDuringInteraction) {
          // Store the answer for subsequent events
          prevent = !confirm(MESSAGE);
          alreadyAskedDuringInteraction = true;
          // At the start of a new interaction,
          // clear that we've asked so we ask again properly
          $(document).one('mousedown touchstart keydown', () => {
            alreadyAskedDuringInteraction = false;
          });
        }

        if (prevent) {
          // Prevent default browser behaviour
          event.preventDefault();
          // Sadly, Rails UJS doesn't account for default being prevented
          // before triggering the `data-remote` handler :(
          event.stopImmediatePropagation();
        } else {
          formHasChanges = false;
        }
      }
    }
  }
}
