/**
 * Declare DOM updates to be run only when JS is available
 * @module lib/with_js
 */
// TODO: Maybe replace with the NPM package: https://www.npmjs.com/package/@cookieshq/with-js. Note that you'll need to pass a specific list of actions due to different naming for default values as well as some specific behaviours on the addAttribute

import { EVENT_WILL_INSERT } from './dom';

const SELECTOR = '[class*="js-with-js"]';

const ACTIONS = {
  injectText: function(el) {
    el.parentNode.replaceChild(document.createTextNode(el.innerText), el);
  },
  addClass: function(el, className) {
    el.classList.add(className);
  },
  removeClass: function(el, className) {
    el.classList.remove(className);
  },
  addAttribute: function(el, attributes) {
    const [attributeName, attributeValue] = attributes.split('--');
    if (attributeName == 'data-toggle-target') {
      el.setAttribute(attributeName, '#' + attributeValue);
    } else {
      el.setAttribute(attributeName, attributeValue || '');
    }
  },
  removeAttribute: function(el, attribute) {
    el.removeAttribute(attribute);
  }
};

function prepareElement(el) {
  Array.from(el.classList)
    .filter(c => /js-with-js--/.test(c))
    .map(c => {
      const [actionName, param] = c.replace(/js-with-js--/, '').split('__');
      const action = ACTIONS[actionName];
      if (action) {
        action(el, param);
      }
    });
}

export function withJS(el = document) {
  [...el.querySelectorAll(SELECTOR)].forEach(prepareElement);
}

export default function setupWithJS(el = document) {
  withJS(el);
  $(document).on('turbolinks:load', () => {
    withJS(el);
  });
  $(document).on(EVENT_WILL_INSERT, function({ detail }) {
    withJS(detail.html);
  });
  $(document).on('cocoon:before-insert', (e, [tabpanel]) => {
    withJS(tabpanel);
  });
}
