import { SELECTOR_CONTROL } from './plan_variants';

const SELECTOR = '.js-plan-variants-sync';

/*
 * Synchronises selected plan variant between
 * monthly and annual tabs for easier comparison
 */
export default function setupPlanVariantsSync() {
  $(document).on(
    'change',
    SELECTOR_CONTROL,
    unlessRunning(({ target }) => {
      const controls = $(target)
        .closest(SELECTOR)
        .find(SELECTOR_CONTROL);

      const selectedPlanLevel = selectedOption(target).dataset.planLevel;

      controls.each(function(index, element) {
        if (element !== target) {
          // No need to process the visible select
          const planIndex = [...element.options].findIndex(
            option => option.dataset.planLevel == selectedPlanLevel
          );
          element.selectedIndex = planIndex;
          element.dispatchEvent(new CustomEvent('change', { bubbles: true }));
        }
      });
    })
  );
}

function selectedOption(selectElement) {
  return selectElement.options[selectElement.selectedIndex];
}

function unlessRunning(fn) {
  let running;
  return (...args) => {
    try {
      if (!running) {
        running = true;
        return fn(...args);
      }
    } finally {
      running = false;
    }
  };
}
