const SELECTOR = '.js-iframe-modal';
const ATTR_URL = 'data-url';

export default function setupIframeModal() {
  $(document).on('show.bs.modal', SELECTOR, event => {
    // Grab the URL from the button that opened the iframe
    $('iframe', event.target).prop(
      'src',
      event.relatedTarget.getAttribute(ATTR_URL)
    );
  });

  $(document).on('hide.bs.modal', SELECTOR, event => {
    // Clear the source of the modal to stop playback
    $('iframe', event.target).prop('src', '');
  });
}
