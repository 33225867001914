import setupProjectErrors from './lib/project_errors';
import setupDrawer from './lib/drawer';
import setupSidebarLoadingConcurrency from './lib/sidebar_loading_concurrency';
import setupChoicePropertyFieldsAndDetails from '../lib/choice_property_details';
import setupIframeModal from './lib/iframe_modal';
import setUpExpressEmbed from './lib/express_embed.js';
import selectFieldsets from './lib/select_fieldsets';

const SELECTOR = '.js-studio';
const SELECTOR_LOADER = '.js-studio__loader';

export default function setupStudio() {
  const el = document.querySelector(SELECTOR);
  // When Chrome restores tabs after having been closed
  // the `turbolinks:load` event gets triggered and would
  // re-run the studio set up if hooked to Turbolinks
  // so adding a little safety to make sure we don't
  // re-set up the studio, leading to two graphs getting
  // displayed, splitting the viewport
  if (el && !el.hasAttribute('data-studio-setup')) {
    el.setAttribute('data-studio-setup', '');
    if (window.wrpIslands) {
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "studio-js" */
        './studio'
      )
        .then(({ default: setupIslandEditor }) => {
          const teardown = setupIslandEditor(el, window.wrpIslands);
          $(SELECTOR_LOADER).remove();
          return teardown;
        })
        .catch((e) => {
          throw e;
        });
    }
  }

  // TODO: These may be best be loaded from the `studio.js` file
  // to ensure proper code splitting. There may also be some code
  // inside the `lib` folder that would be worth sending there too
  setupProjectErrors();
  setupDrawer();
  setupSidebarLoadingConcurrency();
  setupChoicePropertyFieldsAndDetails();
  setupIframeModal();
  setUpExpressEmbed();
  selectFieldsets();
}
