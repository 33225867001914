const V3_URL = 'https://js.stripe.com/v3';
let stripe;
let displayError;
let successPath;
let submitButton;
let paymentMethodPath;
let name;
let country;
let couponCodeId;
let vat;
let plan_id;
let is_business;
let business_line1;
let business_line2;
let business_city;
let business_postcode;
let business_number;
let company_name;
let options = {
  style: {
    base: {
      iconColor: '#0074e6',
      color: '#444',
      fontWeight: 400,
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      '::placeholder': {
        color: '#adb5bd',
      },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
};

document.addEventListener('turbolinks:load', function () {
  const form = document.querySelector('.js-stripe-form');

  form &&
    (async () => {
      const Stripe = await loadStripe();
      const publishableKey = document.querySelector(
        "meta[name='stripe-publishable-key']"
      ).content;
      stripe = Stripe(publishableKey);
      displayError = document.getElementById('card-errors');
      successPath = form.getAttribute('data-success-path');
      paymentMethodPath = form.getAttribute('action');
      submitButton = document.getElementById('card-submit');
      name = document.getElementById('card-name');
      country = document.getElementById('country');
      vat = document.getElementById('vat-number');
      plan_id = document.getElementById('plan-id');
      is_business = document.getElementById('is_business');
      business_number = document.getElementById('business_number');
      business_line1 = document.getElementById('business-address-line-1');
      business_line2 = document.getElementById('business-address-line-2');
      business_city = document.getElementById('business-address-city');
      business_postcode = document.getElementById('business-address-postcode');
      company_name = document.getElementById('company_name');
      const elements = stripe.elements();

      const card = elements.create('card', options);
      card.mount('#card-element');

      card.addEventListener('change', ({ error }) => {
        if (error) {
          displayError.textContent = error.message;
        } else {
          displayError.textContent = '';
        }
      });

      form.addEventListener('submit', async (event) => {
        event.preventDefault();
        submitButton.disabled = true;

        stripe
          .createPaymentMethod(paymentMethodParams(card))
          .then(stripePaymentMethodHandler)
          .catch(() => {
            displayError.textContent = 'An error occurred';
            submitButton.disabled = false;
          });
      });
    })();

  const couponForm = document.querySelector('.js-subscription-coupon-form');
  const couponSubmitButton = document.getElementById('js-coupon-submit-button');

  if (!couponForm) return;
  couponForm.addEventListener('submit', (event) => {
    event.preventDefault();
    const subscriptionCouponRedeemPath = couponForm.getAttribute('action');

    const couponId = document.getElementById(
      'subscription_coupon_coupon_id'
    ).value;
    const subscriptionCouponRedeemFormPayload = {
      coupon_id: couponId,
      plan_id: document.getElementById('plan-id').value,
    };

    fetch(subscriptionCouponRedeemPath, {
      method: 'post',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(subscriptionCouponRedeemFormPayload),
      credentials: 'same-origin',
    })
      .then(function (result) {
        if (result.ok) {
          return result.json();
        }
        throw new Error(result);
      })
      .then(function (response) {
        console.log('response', response);

        // show the discount row
        document
          .querySelector('.js-coupon-row-wrapper')
          .classList.remove('d-none');
        // show the coupon name
        document.querySelector('.js-coupon-code-display').innerHTML = couponId;

        // show the discounted amounts
        document.querySelector('.js-coupon-discount-amount').innerHTML =
          '-$' + (response.discount / 100).toFixed(2);
        document.querySelector('.js-total-payment-element').innerHTML =
          '$' + (response.discounted_total / 100).toFixed(2);

        // Show information
        document
          .querySelector('.js-coupon-payment-info')
          .classList.remove('d-none');
        // Hide coupon form
        couponForm.classList.add('d-none');

        // Set coupon id for payload later
        couponCodeId = couponId;

        return response;
      })
      .catch(() => {
        // Enable the button for a new check
        couponSubmitButton.disabled = false;

        // Display error message
        let errorMessageElement = document.querySelector('.js-coupon-error');
        errorMessageElement.innerHTML =
          'Your coupon code is invalid. Please try again.';
        errorMessageElement.classList.remove('d-none');
      });
  });
});
function stripePaymentMethodHandler(result) {
  if (result.error) {
    displayError.textContent = result.error.message;
    submitButton.disabled = false;
  } else {
    fetch(paymentMethodPath, {
      method: 'post',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(payload(result.paymentMethod.id)),
      credentials: 'same-origin',
    })
      .then(function (result) {
        return result.json();
      })
      .then(paymentMethodResponseHandler)
      .catch(() => {
        displayError.textContent = 'An error occurred';
        submitButton.disabled = false;
      });
  }
}

function paymentMethodResponseHandler(response) {
  if (response.success) {
    window.location.replace(successPath);
  } else if (response.requires_action) {
    stripe
      .confirmCardPayment(response.payment_intent_client_secret)
      .then(confirmCardPaymentHandler)
      .catch(() => {
        displayError.textContent = 'An error occurred';
        submitButton.disabled = false;
      });
  } else if (response.errors) {
    submitButton.disabled = false;
    renderErrors(response.errors);
  } else {
    submitButton.disabled = false;
    displayError.textContent = response.error_message;
  }
}

function confirmCardPaymentHandler(result) {
  if (result.error) {
    submitButton.disabled = false;
    displayError.textContent = result.error.message;
  } else {
    window.location.replace(successPath);
  }
}

function loadStripe() {
  if (window.Stripe) {
    return window.Stripe;
  }

  const script = document.querySelector(`script[src="${V3_URL}"]`);

  return new Promise((resolve, reject) => {
    script.addEventListener('load', () => {
      if (window.Stripe) {
        resolve(window.Stripe);
      } else {
        reject(new Error('Failed to load Stripe.js'));
      }
    });

    script.addEventListener('error', () => {
      reject(new Error('Failed to load Stripe.js'));
    });
  });
}

function clearErrors() {
  displayError.textContent = '';
  document
    .querySelectorAll('.invalid-feedback')
    .forEach((el) => el.parentNode.removeChild(el));
  document
    .querySelectorAll('.is-invalid')
    .forEach((el) => el.classList.remove('is-invalid'));
}

function renderErrors(errors) {
  clearErrors();

  const attributes = Object.keys(errors);
  for (const attribute of attributes) {
    const element = document.querySelector(
      `[name='subscriptions_subscription_creator[${attribute}]']`
    );

    if (element) {
      const errorSpan = document.createElement('span');
      errorSpan.textContent = errors[attribute];
      errorSpan.classList.add('invalid-feedback');
      element.classList.add('is-invalid');
      element.insertAdjacentElement('afterend', errorSpan);
    }
  }
}

function paymentMethodParams(card) {
  var params = {
    type: 'card',
    card: card,
    billing_details: {
      name: name.value || undefined,
    },
  };

  if (country) {
    params['billing_details']['address'] = {
      country: country.value || undefined,
    };
  }

  if (vat) {
    params['metadata'] = { vat_number: vat.value || undefined };
  }

  return params;
}

function payload(paymentMethodId) {
  if (paymentMethodPath == '/subscriptions') {
    return {
      subscription: {
        payment_method_id: paymentMethodId,
        country: country.value,
        vat_number: vat.value,
        name: name.value,
        company_name: company_name.value,
        plan_id: plan_id.value,
        is_business: is_business.checked,
        business_number: business_number.value,
        business_address_line1: business_line1.value,
        business_address_line2: business_line2.value,
        business_address_city: business_city.value,
        business_address_postcode: business_postcode.value,
        coupon_code: couponCodeId,
      },
    };
  } else {
    return {
      payment_method: {
        payment_method_id: paymentMethodId,
        name: name.value,
      },
    };
  }
}
