/**
 * Handles the Rails UJS response for replacing form
 * when switching between script and non script edition
 * of the islands.
 * @module lib/island_edit_modes
 */
import { replace } from './dom';

/** CSS selector for the element on which to listen to the UJS events*/
const SELECTOR = '.js-island-edit-modes';
/** CSS selector to target specific elements more specifically */
const SELECTOR_SOURCE = '.js-island-edit-modes__trigger';

export default function setupIslandEditModes() {
  $(document).on('ajax:success', SELECTOR_SOURCE, ({ target, detail }) => {
    if ($(target).is(SELECTOR_SOURCE)) {
      const [response] = detail;
      const existing = findExisting(target)[0];
      const updated = response.querySelector(SELECTOR);
      replace(updated, existing);
    }
  });

  // We don't want to do unnecessary replacement on active tabs
  $(document).on('ajax:before', SELECTOR_SOURCE, event => {
    if ($(event.target).hasClass('active')) {
      event.preventDefault();
    }
  });
}

function findExisting(source) {
  return $(source).closest(SELECTOR);
}
