/**
 * Little helper to check if given element is matches or is within,
 * mainly used for doing event delegation
 * @param {*} selector
 * @param {*} el
 * @returns The closest matching elements
 */
export default function isWithin(selector, el) {
  return $(el).closest(selector)[0];
}
