import { EVENT_WILL_INSERT } from './dom';

export default function setupVariableFormValueType() {
  setup();
  setListeners();
}

function setListeners() {
  $(document).on('turbolinks:load', function () {
    setup();
  });

  $(document).on(EVENT_WILL_INSERT, ({ detail }) => {
    setup(detail.html);
  });
}

function setup(parent = document) {
  const SELECTOR = 'form select#variable_variable_value_type';
  const $select = $(parent).find(SELECTOR);

  if ($select.length === 0) {
    return;
  }

  $select.on('change', (e) => {
    const variableType = e.target.value;
    toggleListsHint(variableType);
    toggleBoolSelect(variableType);
  });

  $select.trigger('change');
}

function toggleBoolSelect(variableType) {
  const $target = $('#variable_variable_starting_value'),
    $booleanWrapper = $('.boolean-starting-value-wrapper'),
    $nonBooleanWrapper = $('.non-boolean-starting-value-wrapper');
  switch (variableType) {
    case 'list':
      $target.attr('type', 'text');
      hideEl($booleanWrapper);
      showEl($nonBooleanWrapper);
      break;
    case 'text':
      $target.attr('type', 'text');
      hideEl($booleanWrapper);
      showEl($nonBooleanWrapper);
      break;
    case 'number':
      $target.attr('type', 'number');
      hideEl($booleanWrapper);
      showEl($nonBooleanWrapper);
      break;
    case 'boolean':
      showEl($booleanWrapper);
      hideEl($nonBooleanWrapper);
      break;
    default:
      break;
  }
}

function toggleListsHint(variableType) {
  const $badge = $('.variable_variable_starting_value .badge');
  if (variableType === 'list') {
    $badge.show();
  } else {
    $badge.hide();
  }
}

function showEl($wrapper) {
  $wrapper.removeClass('d-none');
  $wrapper.find('input, select').removeAttr('disabled');
}

function hideEl($wrapper) {
  $wrapper.addClass('d-none');
  $wrapper.find('input, select').attr('disabled', 'disabled');
}
