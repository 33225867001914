/**
 * Apply select2 automatically
 * @module lib/js_select2
 */

import { EVENT_WILL_INSERT } from './dom';

export const DEFAULT_SELECT2_OPTS = {
  theme: 'bootstrap'
};

export default function setupJsSelect2() {
  $(document).on('cocoon:after-insert', (event, [wrapper]) => {
    setupSelect2Automatically(wrapper);
  });

  $(document).on('turbolinks:load', function () {
    setupSelect2Automatically(document);
  });

  $(document).on(EVENT_WILL_INSERT, function ({ detail }) {
    setupSelect2Automatically(detail.html);
  });
}

function setupSelect2Automatically(wrapper) {
  $(wrapper)
    .find('.js-select2')
    .each(function () {
      $(this).select2( DEFAULT_SELECT2_OPTS );
    });
}
