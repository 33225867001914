export function projectTemplates() {
  const links = document.querySelectorAll('.js-create-template');

  // Once the template link is clicked,
  // Hide all of the links and show the loader bar instead
  links.forEach((link) => {
    const showLoadersOnClick = () => {
      document.querySelector('.templates-wrapper').classList.toggle('d-none');
      document.querySelector('.template-loader').classList.toggle('d-none');
    };

    link.addEventListener('click', showLoadersOnClick);
  });
}
