const SELECTOR = '.js-default-choice';
const SELECTOR_TAB_PANEL = '.js-tab-content-choices';

export default function setupUpdateCheckboxes() {
  $(document).on('drawer:did-open', () => {
    $(document).on('change', SELECTOR, limitDefaultChoices);
  });

  $(document).on('drawer:did-close', teardown);

  $(document).on('turbolinks:before-cache', teardown);

  function limitDefaultChoices() {
    const checkboxList = $(this)
      .closest(SELECTOR_TAB_PANEL)
      .find(SELECTOR);

    checkboxList.each((ind, el) => {
      if (!$(el).is(this)) {
        $(el).prop('checked', false);
      }
    });
  }

  function teardown() {
    $(document).off('change', SELECTOR, limitDefaultChoices);
    $(document).off('turbolinks:before-cache', teardown);
  }
}
