/**
 * Enhances a `type="file"` input with a nicer UI
 * handling the replacement and deletion of the file
 *
 * UI updates are driven by the `change` event of the input
 * fields (with `CustomEvent` being triggered when we change
 * the value ourselves)
 *
 * @module lib/file_upload
 */
import isWithin from './is_within';

/** CSS selector for the wrapper element */
export const SELECTOR = '.js-file-upload';
/** CSS selector for the element that should trigger a file selection */
const SELECTOR_SELECT_FILE = '.js-file-upload__selectFile';
/** CSS selector for the upload input itself */
const SELECTOR_FIELD = '.js-file-upload__field';
/** CSS selector for the thumbnail */
const SELECTOR_THUMBNAIL = '.js-file-upload__thumbnail';
/** CSS selector for the cancel button */
const SELECTOR_CANCEL = '.js-file-upload__cancel';
/** CSS selector for the remove button */
const SELECTOR_REMOVE = '.js-file-upload__remove';
/** CSS selector for the input triggering the deletion of the file */
const SELECTOR_REMOVE_FIELD = '.js-file-upload__remove_field';
/** CSS selector for the element displaying the filename */
const SELECTOR_FILENAME = '.js-file-upload__filename';
/** CSS selector for sections to be disabled when file is present */
const SELECTOR_DISABLE = '.js-file-upload__disable';
export const ADOBE_FILE_UPLOAD = '.js-adobe-file-upload';
export const LOCAL_FILE_UPLOAD = '.js-local-file-upload';
export const IMAGE_UPLOAD = '.js-general-image-upload';

// The different states of the field
const CLASS_EMPTY = 'wrp-file-upload--empty';
export const CLASS_WITH_SELECTION = 'wrp-file-upload--with-selection';
const CLASS_IS_REMOVING = 'wrp-file-upload--is-removing';

// Attribute for storing the original thumbnail URL
const DATA_THUMBNAIL_SRC = 'data-value';

export const updateSelectorClasses = (
  selector,
  classListRemove,
  classListAdd
) => {
  const element = document.querySelector(selector);

  if (element) {
    element.classList.remove(...classListRemove);
    element.classList.add(...classListAdd);
  }
};

export default function setupFileUpload() {
  $(document).on('click', SELECTOR, ({ target, currentTarget }) => {
    if (isWithin(SELECTOR_SELECT_FILE, target)) {
      selectFile(currentTarget);
      return;
    }

    if (isWithin(SELECTOR_CANCEL, target)) {
      cancelUpload(currentTarget);
      return;
    }

    if (isWithin(SELECTOR_REMOVE, target)) {
      cancelUpload(currentTarget);
      return toggleFileRemoval(currentTarget);
    }
  });

  $(document).on('change', SELECTOR, ({ target, currentTarget }) => {
    if (isWithin(SELECTOR_REMOVE_FIELD, target)) {
      handleRemoveChange(target, currentTarget);
    }

    if (isWithin(SELECTOR_FIELD, target)) {
      handleFileChange(target, currentTarget);
    }
  });
}

function handleRemoveChange(removeInputElement, fileUploadContainer) {
  $(fileUploadContainer).toggleClass(
    CLASS_IS_REMOVING,
    removeInputElement.checked
  );
}

function handleFileChange(fileInputElement, fileUploadContainer) {
  const img = fileUploadContainer.querySelector(SELECTOR_THUMBNAIL);
  const filename = fileUploadContainer.querySelector(SELECTOR_FILENAME);

  if (fileInputElement.value) {
    // Swap to having selection
    $(fileUploadContainer)
      .removeClass(CLASS_EMPTY)
      .addClass(CLASS_WITH_SELECTION);

    // render image preview
    if (img) {
      renderThumbnail(fileInputElement, img);

      updateSelectorClasses(ADOBE_FILE_UPLOAD, ['w-50'], ['d-none']);
      updateSelectorClasses(
        LOCAL_FILE_UPLOAD,
        ['d-flex', 'flex-space-evenly'],
        []
      );
      updateSelectorClasses(IMAGE_UPLOAD, ['w-50'], []);
    }
    // Backup text for restoring it
    $(filename).attr('data-text', $(filename).text());
    $(filename).text(fileInputElement.files[0].name);
    // If the field was set to remove the image, cancel it
    cancelFileRemoval(fileUploadContainer);
    $(SELECTOR_DISABLE, fileUploadContainer).attr('disabled', '');
  } else {
    $(fileUploadContainer)
      .removeClass(CLASS_WITH_SELECTION)
      // Only revert to the empty state if there wasn't a thumbnail image already
      .toggleClass(CLASS_EMPTY, isEmpty(fileUploadContainer));
    // Restore thumbnail to its original source
    if (img) {
      img.src = fileUploadContainer.getAttribute(DATA_THUMBNAIL_SRC);
    }
    $(filename).text($(filename).attr('data-text'));
    $(SELECTOR_DISABLE, fileUploadContainer).removeAttr('disabled');
  }
}

function isEmpty(fileUploadContainer) {
  return !(
    fileUploadContainer.getAttribute(DATA_THUMBNAIL_SRC) ||
    $(fileUploadContainer)
      .find('.js-file-upload-on-change__attachment_id')
      .prop('value')
  );
}

function cancelFileRemoval(fileUploadContainer) {
  const input = fileUploadContainer.querySelector(SELECTOR_REMOVE_FIELD);
  if (input) {
    input.checked = false;
    $(input).trigger('change');
  }
}

function toggleFileRemoval(fileUploadContainer) {
  const input = fileUploadContainer.querySelector(SELECTOR_REMOVE_FIELD);
  if (input) {
    input.checked = !input.checked;
    $(input).trigger('change');
  }
}

export function selectFile(fileUploadContainer) {
  fileUploadContainer.querySelector(SELECTOR_FIELD).click();
}

export function filesSelected(fileUploadContainer, files) {
  let field = fileUploadContainer.querySelector(SELECTOR_FIELD);
  field.files = files;
  $(field).trigger('change');
}

function cancelUpload(fileUploadContainer) {
  const input = fileUploadContainer.querySelector(SELECTOR_FIELD);
  input.value = null;
  $(input).trigger('change');
}

function renderThumbnail(fileInputElement, imgElement) {
  var reader = new FileReader();
  reader.onload = function () {
    var dataURL = reader.result;
    imgElement.src = dataURL;
  };
  reader.readAsDataURL(fileInputElement.files[0]);
}
