import { EVENT_WILL_INSERT } from '../dom';

const ADD_BEHAVIOURS_BTN_CLASSNAME = 'cocoon-add-behaviours-association';

export default function setupChoicesAdvancedLogic() {
  $(document).on('cocoon:after-insert', (event, [wrapper]) => {
    // when adding a behaviour block, add a first behaviour by default
    if (wrapper.classList.contains('behaviour-block-fields')) {
      $(wrapper).find(`.${ADD_BEHAVIOURS_BTN_CLASSNAME}`).trigger('click');
    }

    const addLink = event.target.children[0];
    if (addLink.classList.contains(ADD_BEHAVIOURS_BTN_CLASSNAME)) {
      updateAddBehaviourCopytext(wrapper);
    }
  });

  const choiceTabSelector = '.js-choice-tabs__tabpanel';

  $(document).on('turbolinks:load', function () {
    moveElseIfBtn(document);

    $(document)
      .find(choiceTabSelector)
      .each(function () {
        // apply for each tab found
        listenLinkToChanges(this);
      });
  });

  $(document).on(EVENT_WILL_INSERT, function ({ detail }) {
    moveElseIfBtn(detail.html);

    $(detail.html)
      .find(choiceTabSelector)
      .each(function () {
        // apply for each tab found
        listenLinkToChanges(this);
      });
  });

  $(document).on('cocoon:before-insert', function (e, [wrapper]) {
    moveElseIfBtn(wrapper);
    // wrapper is the choice tab itself
    listenLinkToChanges(wrapper);
  });
}

function updateAddBehaviourCopytext(wrapper) {
  var addBehaviourLink = $(wrapper).closest('.behaviour-block-fields').find(`.${ADD_BEHAVIOURS_BTN_CLASSNAME}`);

  var linkText = addBehaviourLink.html();
  addBehaviourLink.html(linkText.replace('Add change', 'Add another change'));
}

function moveElseIfBtn(wrapper) {
  $(wrapper)
    .find('.condition-blocks-wrapper')
    .each(function () {
      const $blocksWrapper = $(this);
      const $btn = $blocksWrapper.find('.add-else-if-condition-btn').detach();
      $blocksWrapper.find('.if--condition-block-fields:last').after($btn);
    });
}

function listenLinkToChanges(choiceTab) {
  const IF_SELECTOR = '.d-if--conditional-block',
    UNLESS_SELECTOR = '.d-unless--conditional-block';

  const $choiceTab = $(choiceTab),
    $select = $choiceTab.find('.js-linked-variant-select:first'),
    $collapsibleConditionBlocks = $choiceTab.find('.conditional-logic-blocks-collapsible .js-collapsible-field');

  $select.on('change', function (e) {
    const $ifContent = $choiceTab.find(IF_SELECTOR),
      $unlessContent = $choiceTab.find(UNLESS_SELECTOR);
    /** we'll set style='display: none' to toggle these elements
     */
    if (e.target.value === 'advanced_logic') {
      $ifContent.removeAttr('style');
      $unlessContent.css('display', 'none');
      $collapsibleConditionBlocks.addClass('show');
    } else {
      $ifContent.css('display', 'none');
      $unlessContent.removeAttr('style');
      $collapsibleConditionBlocks.removeClass('show');
    }
  });

  // trigger once so UI is in sync
  $select.trigger('change');
}
