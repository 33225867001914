const SELECTOR = '.js-internal-link-options';
const SELECTOR_SELECT = '.js-internal-link-options__select';
const SELECTOR_OPTIONS = '.js-internal-link-options__options';

export default function setupInternalLinkOptions() {
  document.addEventListener('change', ({ target }) => {
    const wrapper = target.closest(SELECTOR);
    // Not only do we need to check that we're inside the component
    // but also that it's the select that triggered the change
    if (wrapper && target.closest(SELECTOR_SELECT)) {
      const options = wrapper.querySelector(SELECTOR_OPTIONS);
      // There can be multiple links for a given choice
      const selects = [...wrapper.querySelectorAll(SELECTOR_SELECT)];
      if (selects.some(select => select.value === 'external_url')) {
        options.setAttribute('disabled', '');
      } else {
        options.removeAttribute('disabled');
      }
    }
  });
}
