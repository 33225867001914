const SELECTOR = '.js-project-errors';
const SELECTOR_MESSAGES = '.js-project-errors__messages';

export default function setupProjectErrors() {
  // Handle the opending and closing of the error notification
  $(document).on('show.bs.collapse', SELECTOR, ({ currentTarget }) => {
    $(currentTarget).addClass('wrp-project-errors--expanded');
  });
  $(document).on('hide.bs.collapse', SELECTOR, ({ currentTarget }) => {
    $(currentTarget).removeClass('wrp-project-errors--expanded');
  });
}

export function renderExportErrors(errorsHTML) {
  if (errorsHTML) {
    $(SELECTOR).removeClass('d-none');
    const $errorMessages = $(SELECTOR_MESSAGES);
    const $replacement = $(errorsHTML);
    if ($errorMessages.hasClass('show')) {
      $replacement.addClass('show');
    }
    $errorMessages.html($replacement);
  } else {
    $(SELECTOR).addClass('d-none');
  }
}
