/**
 * A module for showing and hiding elements via a checkbox/toggle
 * @module lib/show_hide
 */

export default function showHide() {
  // attach on page load
  runShowHide();

  // reattach on turbolinks load (e.g. publish/unpublish project)
  document.addEventListener('turbolinks:load', () => {
    runShowHide();
  });

  // reattach when form is replaced
  document.addEventListener('ajax:error', (event) => {
    if (event.target instanceof HTMLFormElement) {
      runShowHide();
    }
  });
}

function runShowHide() {
  document.querySelectorAll('[data-show-hide]').forEach((element) => {
    const targets = document.querySelectorAll(`[data-show-hide-target="${element.dataset.showHide}"]`);

    // use dataset to know if we need to attach the event listener. This avoids attaching the event listener twice on initial page load where turbolinks:load sometimes is fired and sometimes is not
    if (!element.dataset.attached) {
      element.dataset.attached = true;

      element.addEventListener('change', () => {
        targets.forEach((target) => {
          target.classList.toggle('d-none');
        });
      });
    }
  });
}
