/**
 * Adds limitation to the number of repetitions allowed
 * in a field managed by cocoon
 * See the relevant `app/helpers/cocoon_limit_helper.rb` helper
 * for the setting of the options, as well as the relevant styles in
 * `app/webpack/styles/*components/_cocoon_limit.scss`
 * @module lib/cocoon_limit
 */

// The class marking the feature. To be set on one of the parents
// of both the cocoon fields and any "link_to_add_association" related
// to it. Use a `.js-cocoon-limit--<identifier>` to serparate styles
// between different sections
const SELECTOR = '.js-cocoon-limit';

// Stores the current number of elements
const ATTR_COUNT = 'data-cocoon-limit-count';
// The maximum number of elements allowed
const ATTR_MAX = 'data-cocoon-limit-max';
// The minimum of elements allowed
const ATTR_MIN = 'data-cocoon-limit-min';

// The feature works by adding two classes that'll hide
// either the add (when max limit is reached) or
// delete button (when min limit is reached)
const CLASS_NO_ADD = 'wrp-cocoon-limit--no-add';
const CLASS_NO_DELETE = 'wrp-cocoon-limit--no-delete';

// Help limit the amount fo
export default function setupCocoonLimit() {
  $(document).on('cocoon:after-insert', (event, [tabpanel]) => {
    updateTabCount(tabpanel, 1);
  });
  // Need to use before remove to find the appropriate container
  // after-remove, the tabpanel has no parent
  $(document).on('cocoon:before-remove', (event, [tabpanel]) => {
    const classList = event.target.classList;
    const elementTargetType = event.target.dataset.targetType;

    // TODO: Move the confirmation code out of this module
    // and into its own `cocoon-confirm` module
    const question = `Are you sure you want to delete this ${
      elementTargetType || targetType(classList)
    }?  This is a permanent action and cannot be recovered.`;
    const removeVariant = confirm(question);

    if (removeVariant) {
      updateTabCount(tabpanel, -1);
      if ($('.island-behaviour').length == 1) {
        var addBehaviourLink = $('.cocoon-add-behaviours-association');
        var linkText = addBehaviourLink.html();
        addBehaviourLink.html(
          linkText.replace('Add another change', 'Add change')
        );
      }
    } else {
      event.preventDefault();
      event.result.val = false;
    }
  });
}

function targetType(classList) {
  if (classList.contains('js-cocoon-variants')) {
    return 'variant';
  } else if (classList.contains('js-cocoon-behaviour-blocks')) {
    return 'behaviour block';
  } else if (classList.contains('js-cocoon-behaviours')) {
    return 'behaviour';
  } else if (classList.contains('js-cocoon-variant-behaviours')) {
    return 'variant behaviour';
  } else if (classList.contains('js-cocoon-condition-blocks')) {
    return 'condition block';
  } else if (classList.contains('js-cocoon-condition-groups')) {
    return 'condition group';
  } else if (classList.contains('js-cocoon-conditions')) {
    return 'condition';
  } else if (classList.contains('js-cocoon-choices')) {
    return 'choice';
  }
}

function updateTabCount(tabpanel, amount) {
  const $widget = $(tabpanel).closest(SELECTOR);
  const currentCount = parseInt($widget.attr(ATTR_COUNT), 10);
  const newCount = currentCount + amount;
  $widget.attr(ATTR_COUNT, currentCount + amount);
  $widget.toggleClass(CLASS_NO_ADD, newCount >= $widget.attr(ATTR_MAX));
  $widget.toggleClass(CLASS_NO_DELETE, newCount <= $widget.attr(ATTR_MIN));
}
