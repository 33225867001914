/**
 * Marks sources of UJS busy with the `wrp-busy` class
 * while the request is happening
 * @module lib/ujs_busy
 */
const CLASS_BUSY = 'wrp-busy';

export default function setupUjsBusy() {
  $(document).on('ajax:beforeSend', ({ target }) => {
    const $target = $(target);
    $target.addClass(CLASS_BUSY);
    $target.one('ajax:complete', () => {
      $(target).removeClass(CLASS_BUSY);
    });
  });
}
