import { updatePositions } from './position';
import { SELECTOR_FIELDS } from '.';

const SELECTOR_TOGGLE = '.js-cocoon-reorder-toggle';
const ATTRIBUTE_OFFSET = 'data-cocoon-reorder-offset';

export default function setupCocoonReorder() {
  $(document).on('click', SELECTOR_TOGGLE, event => {
    const offset = parseInt(event.currentTarget.getAttribute(ATTRIBUTE_OFFSET));
    const field = event.currentTarget.closest(SELECTOR_FIELDS);

    shiftFields(field, offset);
  });
}

function shiftFields(nestedField, offset) {
  const hasMoved = shift(nestedField, offset, {
    selector: SELECTOR_FIELDS
  });
  if (hasMoved) {
    updatePositions(nestedField.parentElement);
    moveTab(nestedField, offset);
  }
}

function shift(nestedField, offset = 1, { selector } = {}) {
  let fields = selector
    ? nestedField.parentElement.querySelectorAll(selector)
    : nestedField.parentElement.children;

  // Need to transform it into an Array to be sure to have `indexOf`
  fields = [...fields];

  const fieldPosition = fields.indexOf(nestedField);
  const newPosition = fieldPosition + offset;

  // Only move if the new position is in range
  const fieldAtNewPosition = fields[newPosition];
  if (fieldAtNewPosition) {
    fieldAtNewPosition.insertAdjacentElement(
      offset > 0 ? 'afterend' : 'beforebegin',
      nestedField
    );
  }

  // Return whether a move happened or not
  return !!fieldAtNewPosition;
}

function moveTab(field, offset) {
  // Go back up to the closest list of tabs
  const tab = document.querySelector(`[data-target="#${field.id}"]`);
  if (tab) {
    shift(tab, offset);
  }
}

window.shiftFields = shiftFields;
