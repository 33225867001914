/**
 * Sets up a color picker based on https://github.com/Simonwep/pickr
 * See `app/helpers/color_picker_helper.rb` for a helper to set it up in the views
 * @module lib/color_picker
 */
import Pickr from '@simonwep/pickr/src/js/pickr';
import { EVENT_WILL_INSERT } from './dom';

/**
 * The class to set on the colopicker's form-group
 */

const SELECTOR = '.js-color-picker';
const SELECTOR_FIELD = 'input';
const SELECTOR_LABEL = 'label';

/**
 * Attribute for setting the default color of the color picker
 */

const ATTR_DEFAULT_COLOR = 'data-color-picker-default';

export default function setupColorPicker() {
  let pickrs = [];
  $(document).on('turbolinks:load', function() {
    pickrs = setup();
  });
  // The EVENT_WILL_INSERT means the form has been completely
  // replaced, so we can tear down previous color pickers
  $(document).on(EVENT_WILL_INSERT, function({ detail }) {
    teardown();
    pickrs = setup(detail.html);
  });

  // Cocoon will just add new tabs so we don't want to teardown
  // existing color pickrs, just keep track of them
  $(document).on('cocoon:before-insert', (e, [tabpanel]) => {
    pickrs = [...pickrs, ...setup(tabpanel)];
  });

  pickrs = setup();

  $(document).on('turbolinks:before-cache', function() {
    teardown();
  });

  function teardown() {
    pickrs.forEach(pickr => {
      pickr.destroyAndRemove();
    });
    pickrs = [];
  }
}

function setup(parent = document) {
  // Not jQuery to get a proper array
  const pickrs = [...parent.querySelectorAll(SELECTOR)].map(el => {
    if (el.classList.contains('stornaway-pickr')) {
      return null;
    }
    el.classList += ' stornaway-pickr';
    const field = el.querySelector(SELECTOR_FIELD);
    // Create a placeholder that'll get replaced by
    const placeholder = document.createElement('div');
    el.insertBefore(placeholder, field);

    const pickr = new Pickr({
      el: placeholder,
      container: el,
      theme: 'monolith',
      color: null,
      components: {
        preview: true,
        palette: true,
        hue: true,
        opacity: true,
        interaction: {
          input: true,
          save: true,
          cancel: true,
          clear: true
        }
      },
      comparison: true,
      swatches: null,
      closeOnScroll: true,
      //
      adjustableNumbers: false,
      default: el.getAttribute(ATTR_DEFAULT_COLOR),
      position: 'bottom-end',
      strings: {
        save: 'Select'
      }
    });

    // Hide the field (but don't disable it so it still submits)
    $(field).addClass('d-none');
    pickr.on('init', function() {
      pickr.setColor(field.value || null);

      // Make the popup focusable so it can be focused on show
      pickr.getRoot().app.setAttribute('tabindex', '-1');
      pickr.on('show', (color, instance) => {
        instance.getRoot().app.focus();
      });

      // Style the 'clear' state to show the default color as placeholder
      pickr.getRoot().button.style.backgroundColor = el.getAttribute(
        ATTR_DEFAULT_COLOR
      );

      // Carry the values from the picker to the field that'll be submitted
      pickr.on('save', (color, instance) => {
        if (color) {
          field.value = `${color.toHEXA()}`;
          // Dispatch events on the original field so other components
          // can listen to changes like on a regular form element
          field.dispatchEvent(new CustomEvent('input', { bubbles: true }));
          field.dispatchEvent(new CustomEvent('change', { bubbles: true }));
        }
        instance.hide();
        instance.getRoot().button.focus();
      });
      pickr.on('clear', (color, instance) => {
        field.value = '';
        instance.hide();
        instance.getRoot().button.focus();
      });
      pickr.on('cancel', (color, instance) => {
        instance.hide();
        instance.getRoot().button.focus();
      });
      adjustPickerUI(pickr);
    });

    $(el).on('click', SELECTOR_LABEL, () => {
      pickr.show();
    });

    return pickr;
  });

  return pickrs.filter((pickr) => pickr);
}

function adjustPickerUI(pickr) {
  const app = pickr.getRoot().app;
  const clearButton = app.querySelector('.pcr-clear');
  // Avoid creating 8 digit hex (with transparency), that are not yet supported in browsers
  $('.pcr-result', app)
    .attr('maxLength', 7)
    .addClass('form-control');
  $('.pcr-swatches', app).remove();

  // Reorder buttons so they go "clear", "cancel", "save"
  const interaction = app.querySelector('.pcr-interaction');
  interaction.insertBefore(app.querySelector('.pcr-save'), clearButton);

  // Create an input-group to link the clean button to the result
  const inputGroup = $(`<div class="input-group">
    <div class="input-group-append"></div>
  </div>`)[0];

  inputGroup.insertBefore(
    app.querySelector('.pcr-result'),
    inputGroup.childNodes[0]
  );
  inputGroup.querySelector('.input-group-append').appendChild(clearButton);
  clearButton.value = '×';

  interaction.insertBefore(inputGroup, app.querySelector('.pcr-cancel'));

  interaction.addEventListener(
    'keydown',
    e => {
      if (e.key === 'Enter') {
        pickr.applyColor(); // "Saves" the color
        pickr.hide(); // Hides the modal
        // You may call e.stopImmediatePropagation(); if you want to prevent event-bubbling
      }
    },
    true
  );
}
