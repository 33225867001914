/**
 * Helps focusing the actual form control when clicking the label
 * of the field
 * @module lib/collapsible_field
 */

const SELECTOR = '.js-collapsible-field';
const SELECTOR_INPUT = 'input,textarea,select';

export default function setupCollapsibleField() {
  $(document).on('shown.bs.collapse', SELECTOR, ({ target }) => {
    $(target)
      .find(SELECTOR_INPUT)
      .first()
      .focus();
  });
}
