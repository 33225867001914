/**
 * Shared cocoon constants and helpers
 */

export const SELECTOR_ALL_FIELDS = '.nested-fields-choices';

// Cocoon leaves deleted fields in the DOM so they can
// be submitted to the server, so we'll need to mark them
// ourselves and provide a selector to ignore them
export const CLASS_DELETED = 'js-cocoon__deleted';
export const SELECTOR_FIELDS = SELECTOR_ALL_FIELDS + `:not(.${CLASS_DELETED})`;

export function markDeleted(fields) {
  fields.classList.add(CLASS_DELETED);
}
