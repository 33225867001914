/**
 * Sets specific element to 'aria-busy' when a UJS request is happening
 */

const ATTR_BUSY_TARGET = 'data-remote-updates';

export default function setupUjsBusyTarget() {
  $(document).on('ajax:beforeSend', ({ target }) => {
    const $target = $(target);
    const selectors = $target.attr(ATTR_BUSY_TARGET);
    if (selectors) {
      const $elements = $(selectors);
      $elements.attr('aria-busy', true);
      $target.one('ajax:complete', () => {
        $elements.removeAttr('aria-busy');
      });
    }
  });
}
