import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

document.addEventListener('turbolinks:load', () => {
  const elements = document.querySelectorAll('[data-check-duplication-url]');

  elements.forEach(element => {
    let interval;

    interval = setInterval(() => {
      Rails.ajax({
        type: 'GET',
        url: element.dataset.checkDuplicationUrl,
        dataType: 'json',
        success: data => {
          switch (data.state) {
            case 'complete':
              clearInterval(interval);
              Turbolinks.visit(window.location);
              break;
            case 'failed':
              clearInterval(interval);
              element.insertAdjacentHTML('afterend', data.html);
              element.remove();
              break;
          }
        }
      });
    }, 3000);

    document.addEventListener('turbolinks:before-cache', () => {
      clearInterval(interval);
    });
  });
});
