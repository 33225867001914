import Turbolinks from 'turbolinks';
import setupStudio from '../scripts/studio';
import setupRoleButton from '../scripts/lib/role_button';
import setupFormTabs from '../scripts/lib/form_tabs';
import setupFileUpload from '../scripts/lib/file_upload';
import setupWithJS from '../scripts/lib/with_js';
import setupFormTabsLabels from '../scripts/lib/form_tabs_labels';
import setupCocoonLimit from '../scripts/lib/cocoon/limit';
import setupChoicesAdvancedLogic from '../scripts/lib/cocoon/choices_advanced_logic';
import setupCocoonReorder from '../scripts/lib/cocoon/reorder';
import setupCocoonPosition from '../scripts/lib/cocoon/position';
import setupRovingTabindex from '../scripts/lib/roving_tabindex';
import setupFileUploadOnChange from '../scripts/lib/file_upload_on_change';
import setupLoadModalContent from '../scripts/lib/load_modal_content';
import setupVideo from '../scripts/videoPlayer';
import setupUjsBusy from '../scripts/lib/ujs_busy';
import setupUjsFormErrors from '../scripts/lib/ujs_form_errors';
import setupUjsErrorNotifications from '../scripts/lib/ujs_error_notifications';
import setupFileUploadProgress from '../scripts/lib/file_upload_progress';
import setupColorPicker from '../scripts/lib/color_picker';
import setupVariableFormValueType from '../scripts/lib/variable_form_value_type';
import checkExport from '../scripts/lib/check_export';
import showHide from '../scripts/lib/show_hide';
import setupIslandEditModes from '../scripts/lib/island_edit_modes';
import setupUpdateCheckboxes from '../scripts/lib/update_checkboxes';
import { setupScriptEditor } from '../scripts/lib/script_editor';
import setupConfirmOutOfFormNavigation from '../scripts/lib/confirm_out_of_form_navigation';
import setupCollapsibleField from '../scripts/lib/collapsible_field';
import { EVENT_WILL_INSERT } from '../scripts/lib/dom';
import setupUjsBusyTarget from '../scripts/lib/ujs_busy_target';
import setupExternalUrlOptions from '../scripts/lib/external_url_options';
import setupInternalLinkOptions from '../scripts/lib/internal_link_options';
import '../scripts/lib/stripe';
import '../scripts/lib/password_toggler';
import '../scripts/lib/check_duplication_state';
import '../scripts/lib/turbolinks_before_cache';
import '../scripts/lib/variant_behaviour_fields';
import setupConditionGroupsUI from '../scripts/lib/condition_group_sync_logical_operators';
import setupBehaviourActionsHandler from '../scripts/lib/variant_behaviour_actions_handler';
import setupConditionVariableTypesHandler from '../scripts/lib/condition_variable_types_handler';
import setupFileUploadValidation from '../scripts/lib/file_upload_validation';
import setupPlanVariants from '../scripts/lib/plan_variants';
import setupPlanVariantsSync from '../scripts/lib/plan_variants_sync';
import setupFileUploadOnModal from '../scripts/lib/file_upload_modal';
import setupProjectExportPage from '../scripts/lib/project_export_page';
import setupJsSelect2 from '../scripts/lib/js_select2';
import { projectTemplates } from '../scripts/lib/dashboard/project_templates';

document.documentElement.classList.remove('no-js');
// Skip first frame to ensure no animation happens on first render
setTimeout(() => {
  document.documentElement.classList.remove('no-transitions');
}, 0);

import(/* webpackChunkName: "vendor" */ '../scripts/vendor')
  .then(function () {
    setupConfirmOutOfFormNavigation();
    setupCollapsibleField();
    setupRoleButton();
    setupUjsBusy();
    setupUjsBusyTarget();
    setupUjsFormErrors();
    setupWithJS();
    setupStudio();
    setupVideo();
    setupFormTabs();
    setupFormTabsLabels();
    setupCocoonLimit();
    setupChoicesAdvancedLogic();
    setupCocoonReorder();
    setupCocoonPosition();
    setupFileUploadOnChange();
    setupFileUploadProgress();
    setupFileUpload();
    setupFileUploadValidation();
    setupFileUploadOnModal();
    setupRovingTabindex();
    setupLoadModalContent();
    setupColorPicker();
    setupConditionGroupsUI();
    setupBehaviourActionsHandler();
    setupConditionVariableTypesHandler();
    setupVariableFormValueType();
    setupIslandEditModes();
    setupScriptEditor();
    setupUpdateCheckboxes();
    checkExport();
    showHide();
    setupExternalUrlOptions();
    setupInternalLinkOptions();
    setupPlanVariants();
    setupPlanVariantsSync();
    setupProjectExportPage();
    projectTemplates();
    setupJsSelect2();
    // Set up last so that other modules can preventDefault
    // to implement custom rendering of errors
    setupUjsErrorNotifications();

    $(document).on(EVENT_WILL_INSERT, ({ detail }) => {
      $('[data-toggle="tooltip"]', detail.html)
        .tooltip({
          trigger: 'click',
        })
        .on('shown.bs.tooltip', function () {
          const tooltip = $(this);
          setTimeout(() => {
            tooltip.tooltip('hide');
          }, 5000); // Hide the tooltip after 5 seconds
        });
    });

    return true;
  })
  .catch(() => {});

require.context('../images', true);
Turbolinks.start();
