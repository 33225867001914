/**
 * Adds the missing space key keyboard interactions for links
 * with `[role="button"]`
 */
export default function setupRoleButton() {
  $(document).on('keydown', '[role="button"]:not(button)', event => {
    if (!event.isDefaultPrevented()) {
      if (event.keyCode == 32) {
        event.preventDefault();
        event.currentTarget.click();
      }
    }
  });
}
