/**
 * Controls the loading of the sidebar content through Rails UJS
 * and offers helpers for configuring the URL used for loading the content
 * as well as triggering loads programmatically.
 * @module lib/load_modal_content
 */
import isWithin from './is_within';
import { replaceContent, EVENT_DID_INSERT } from './dom';
import { addQueryParameter } from './url';

/** Attribute storing the URL */
export const ATTR_CONTENT_URL = 'data-url';

/** Class for showing the errors */
const CLASS_HAS_ERROR = 'wrp-modal-with-loading--has-error';
/** Used to keep wheel from showing on certain contexts such as updading an island via the form. */
const CLASS_IS_HIDDEN = 'wrp-load-modal-content__hidden';

/** Wrapper around the content itself, the loading overlay and the error message */
const SELECTOR = `.js-load-modal-content`;
/** Element to replace with the loaded content */
const SELECTOR_CONTENT = '.js-load-modal-content__content';
/** Element onto which to trigger the loading by setting `aria-busy="true"` */
const SELECTOR_TRIGGER_LOADING = '.js-load-modal-content__trigger-loading';
/** Element onto which to reset the scroll */
// TODO: Move it in its own module
const SELECTOR_RESET_SCROLL = '.js-load-modal-content__reset-scroll';
/** Element to use for checking if the script tab is active */
const SELECTOR_SCRIPT_TAB = '.js-load-modal-content__script-tab';

export default function setupLoadModalContent() {
  // Handle loading state when switching form tabs
  $(document).on('ajax:beforeSend', SELECTOR, ({ currentTarget, target }) => {
    // Only set the loader if the target is marked to trigger it
    if (currentTarget == target || isWithin(SELECTOR_TRIGGER_LOADING, target)) {
      currentTarget.setAttribute('aria-busy', 'true');
    }
  });

  $(document).on(
    'ajax:success',
    SELECTOR,
    ({ currentTarget, target, detail }) => {
      if (currentTarget == target) {
        $(currentTarget).removeClass(CLASS_HAS_ERROR);
        const [response] = detail;
        const form = response.body.children[0];
        const parent = target.querySelector(SELECTOR_CONTENT);
        return replaceContent(form, parent);
      }
    }
  );

  $(document).on(
    'ajax:error',
    SELECTOR,
    ({ currentTarget, target, detail }) => {
      const xhr = detail[2];
      if (!xhr._wasAborted) {
        if (
          currentTarget == target ||
          isWithin(SELECTOR_TRIGGER_LOADING, target)
        ) {
          $(currentTarget).addClass(CLASS_HAS_ERROR);
        }
      }
    }
  );

  $(document).on('ajax:complete', SELECTOR, ({ currentTarget, target }) => {
    if (currentTarget == target || isWithin(SELECTOR_TRIGGER_LOADING, target)) {
      currentTarget.removeAttribute('aria-busy');
    }
  });

  $(document).on(EVENT_DID_INSERT, SELECTOR, ({ currentTarget }) => {
    currentTarget.removeAttribute('aria-busy');
    // Looks like Firefox is anchoring the scroll when replacing elements
    // Unfortunately, the `overflow-anchor` CSS property doesn't seem
    // to have any effect here so we need to take the matter in our own hands
    resetScroll(currentTarget);
  });
}

export function resetScroll(container = document) {
  $(container)
    .find(SELECTOR_RESET_SCROLL)
    .scrollTop(0);
}

export function loadModalContent(modal, showLoadingModal) {
  $(modal).removeClass(CLASS_HAS_ERROR);
  showLoadingModal
    ? $(modal).removeClass(CLASS_IS_HIDDEN)
    : $(modal).addClass(CLASS_IS_HIDDEN);
  const event = new CustomEvent('trigger-data-remote', { bubbles: true });
  modal.dispatchEvent(event);
}

export function configureContentUrl(modal, url) {
  if ($(SELECTOR_SCRIPT_TAB, modal).hasClass('active')) {
    url = addQueryParameter(url, 'edit_mode', 'script');
  }
  modal.setAttribute('data-url', url);
}
