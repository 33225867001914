/**
 * Toggles the options for external URLs when the value
 * gets selected in the linked field
 */

const SELECTOR = '.js-external-url-options';
const SELECTOR_SELECT = '.js-external-url-options__select';
const SELECTOR_OPTIONS = '.js-external-url-options__options';

export default function setupExternalUrlOptions() {
  document.addEventListener('change', ({ target }) => {
    const wrapper = target.closest(SELECTOR);
    // Not only do we need to check that we're inside the component
    // but also that it's the select that triggered the change
    if (wrapper && target.closest(SELECTOR_SELECT)) {
      const options = wrapper.querySelector(SELECTOR_OPTIONS);
      if (target.value === 'external_url') {
        $(options).collapse('show');
      } else {
        $(options).collapse('hide');
      }
    }
  });
}
