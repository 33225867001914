export default function applyTimeValidation(input) {
  // Attach event listener to input for real-time validation
  input.addEventListener('input', function (event) {
    const value = event.target.value.trim(); // Trim whitespace from input
    const isValid = validateTimeFormat(value);

    console.log('Time validation applied');

    if (isValid || value === '') {
      // Apply the 'is-valid' class if valid or empty (considering empty as valid)
      event.target.classList.remove('is-invalid');
      event.target.classList.add('is-valid');
    } else {
      // Apply the 'is-invalid' class if invalid
      event.target.classList.remove('is-valid');
      event.target.classList.add('is-invalid');
    }
  });
}
function validateTimeFormat(value) {
  // Regular expression to match time format like 1m2s or 3m34.3s
  const regex = /^[0-9]+m(([0-5]?[0-9])(\.[0-9]+)?)s$/;
  return regex.test(value);
}
