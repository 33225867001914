export default function toggleLocationField(target) {
  const enabledLocationField = enableLocationFieldForSelectedStyle(target);
  enableStyleFieldIfRequired(target);
  target
    .closest('.form-group')
    .querySelector('.form-text')
    .classList.toggle('d-none', !enabledLocationField);
}

/**
 * Enable the location field corresponding to the currently selected value
 * of given input and returns if it enabled the selection field
 * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} promptStyleInput
 * @return Boolean
 */
function enableLocationFieldForSelectedStyle(promptStyleInput) {
  // A flag to track if a location field is enabled
  let enabledLocationField;

  // Loop through all elements with the class 'js-choice-location-field'
  document.querySelectorAll('.js-choice-location-field').forEach((input) => {
    // Check if the current element's style matches the selected style
    const selectedStyle = input.dataset.promptStyle == promptStyleInput.value;

    // If the styles match, set the flag to true
    if (selectedStyle) {
      enabledLocationField = true;
    }

    // Get the wrapper of the current element
    const locationWrapper = input.closest('.js-choice-location-wrapper');

    // If the selected style is 'custom_button_positions'
    // add the 'd-none' class to the wrapper - this will hide the location field
    if (['custom_button_positions'].includes(promptStyleInput.value)) {
      locationWrapper.classList.add('d-none');
    } else {
      // Toggle the 'd-none' class to show location field if the style is selected, and hide it otherwise
      locationWrapper.classList.toggle('d-none', !selectedStyle);
    }
    // Disable the input if the style is not selected
    input.disabled = !selectedStyle;
  });

  // Return the flag indicating whether a location field is enabled
  return enabledLocationField;
}

function enableStyleFieldIfRequired(promptStyleInput) {
  const selectedPromptStyle = selectedOption(promptStyleInput);
  const requiresSubstyle = JSON.parse(
    selectedPromptStyle.dataset.requiresSubstyle || 'false'
  );
  const defaultSubstyle = selectedPromptStyle.dataset.defaultSubstyle;

  // Make it a proper Array so that browsers without `forEach`
  // on NodeList are OK
  const substyleFields = [
    ...document.querySelectorAll('.js-prompt-substyle-field'),
  ];

  substyleFields.forEach((input) => {
    // Selected style may not allow all substyles...
    updateVisibleOptions(input, requiresSubstyle);
    // ... and if the currently selected substyle just got hidden
    // we need to update which option is selected now
    updateSelectedOptionIfHidden(input);

    // Shows the substyle field
    input
      .closest('.js-prompt-substyle-wrapper')
      .classList.toggle('d-none', !requiresSubstyle);
    input.disabled = !requiresSubstyle;

    if (defaultSubstyle) {
      selectOption(input, defaultSubstyle);
    }
  });
}

function updateVisibleOptions(input, requiredSubstyles) {
  if (Array.isArray(requiredSubstyles)) {
    input.querySelectorAll('option').forEach((option) => {
      const isAvailable = requiredSubstyles.includes(option.value);
      option.disabled = !isAvailable;
      option.classList.toggle('d-none', !isAvailable);
    });

    // Because we may have hidden the option
  } else {
    input.querySelectorAll('option').forEach((option) => {
      option.disabled = false;
      option.classList.remove('d-none');
    });
  }
}

function updateSelectedOptionIfHidden(select) {
  const selected = selectedOption(select);
  if (selected.classList.contains('d-none')) {
    select.selectedIndex = 0;
  }
}

function selectedOption(select) {
  return select.options[select.selectedIndex];
}

function selectOption(select, value) {
  const options = [...select.options];
  select.selectedIndex = options.indexOf(
    options.find((o) => o.value === value)
  );
}
