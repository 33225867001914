import { selectFile, filesSelected } from './file_upload';

export default function setupFileUploadOnModal() {
  const browseFileSelector = '.js-file-upload__selectFileModal';

  $(document).on('click', browseFileSelector, ({ currentTarget }) => {
    let wrapperSelector = $(currentTarget).data('target-wrapper');
    selectFile(document.querySelector(wrapperSelector));
    $('.modal.show').modal('hide');
    return;
  });

  const uploadFileWrapper = '.upload-a-file-wrapper';

  ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
    $(document).on(eventName, uploadFileWrapper, preventDefaults);
  });

  function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  ['dragenter', 'dragover'].forEach((eventName) => {
    $(document).on(eventName, uploadFileWrapper, highlight);
  });

  ['dragleave', 'drop'].forEach((eventName) => {
    $(document).on(eventName, uploadFileWrapper, unhighlight);
  });

  function highlight(e) {
    e.currentTarget.classList.add('highlight');
  }

  function unhighlight(e) {
    e.currentTarget.classList.remove('highlight');
  }

  $(document).on('drop', uploadFileWrapper, handleDrop);

  function handleDrop(e) {
    let dt = e.originalEvent.dataTransfer;
    let files = dt.files;
    let browseBtn = e.currentTarget.querySelector(browseFileSelector);
    let wrapperSelector = $(browseBtn).data('target-wrapper');
    let $inputWrapper = document.querySelector(wrapperSelector);
    filesSelected($inputWrapper, files);
    $('.modal.show').modal('hide');
  }

  // toggle modal-backdrop
  const modalSelector = '.modal-upload-video';

  $(document).on('show.bs.modal', modalSelector, (event) => {
    let modalWrapper = event.currentTarget;
    $(modalWrapper).next('.modal-backdrop').removeClass('d-none');

    const getEl = document.querySelector('.wrp-studio-drawer');
    getEl.classList.add('z-index-1050');
  });

  $(document).on('hide.bs.modal', modalSelector, (event) => {
    let modalWrapper = event.currentTarget;
    $(modalWrapper).next('.modal-backdrop').addClass('d-none');
  });
}
