/**
 * A generic error handler for displaying when UJS requests fail
 * The default rendering can be disabled by using `preventDefault`
 * on the `ajax:error` event
 */
// TODO: Duplicate of the `ujs_form_errors` default behaviour probably

import { notify, ERROR } from './notifications';

export default function setupUjsErrorNotifications() {
  $(document).on('ajax:error', event => {
    // Allow skipping default event notification
    if (!event.isDefaultPrevented()) {
      notify('Sorry, an error occured', ERROR);
    }
  });
}
