import $ from 'jquery';
import { EVENT_WILL_INSERT } from './dom';

$(document).on(EVENT_WILL_INSERT, function ({ detail }) {
  addIndex(detail.html);
});

$(document).on('cocoon:after-insert', function (e, $insertedItem) {
  const nextIndex = $insertedItem.parent().find('.variant-behaviour').length;

  var title = 'Change ' + nextIndex;
  $insertedItem.find('.form-title').html(title);
});

$(document).on('cocoon:after-remove', function () {
  $('.js-cocoon-variant-behaviours').each(function () {
    addIndex(this);
  });
});

function addIndex(wrapper) {
  let index = 0;
  $(wrapper)
    .find('.variant-behaviour')
    .each(function () {
      // heads up
      // cocoon will add style=display:none to the element when
      // removing the relationship. So ignore these to calculate
      // correctly the index.
      // we could listen for cocoon:before-remove event and add
      // another class to target better these; however we'd need
      // to make sure we do remove the element; as there's a
      // confirmation happening.
      // - see scripts/lib/cocoon/limit.js
      const ignoreEl = $(this).css('display') === 'none';
      if (!ignoreEl) {
        index++;
        var title = 'Change ' + index;
        $(this).find('.form-title').html(title);
      }
    });
}

$(document).on('change', '.behaviour-type-select', function () {
  var form = $(this).closest('.nested-fields');
  var toggleables = form.find('.toggleable-on-behaviour-type');
  var valueInputDiv = form.find('.value-input');

  if ($(this).val() === 'variable_within_text') {
    toggleables.each(function () {
      $(this).hide();
    });
    valueInputDiv.removeClass('d-flex');
  } else {
    toggleables.each(function () {
      $(this).show();
    });
    valueInputDiv.addClass('d-flex');
  }
});

$(document).on('change', '.point-in-video-select', function () {
  toggleTimePickers(this);
});

$(document).on(EVENT_WILL_INSERT, function ({ detail }) {
  $(detail.html)
    .find('.point-in-video-select')
    .each(function () {
      toggleTimePickers(this);
    });
});

function toggleTimePickers(wrapper) {
  const $wrapper = $(wrapper);
  const timePickers = $wrapper.parent().find('.time-into-video-picker');

  if ($wrapper.find('select').val() === 'specific_time') {
    timePickers.show();
  } else {
    timePickers.hide();
  }
}
