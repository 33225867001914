/**
 * Helper for displaying notifications
 * @module lib/notifications
 */
export const SUCCESS = 'success';
export const ERROR = 'danger';
export const NOTIFICATION = 'notification';

export function notify(message, notificationType = SUCCESS) {
  $('.js-notifications').html(`
    <div class="alert alert-${notificationType} alert-dismissible">
      ${message}
      <button class="close" data-dismiss="alert">
        <span aria-hidden="true">&times;</span>
        <span class="sr-only">Dismiss</span>
      </button>
    </div>
    `);
}
