import { EVENT_WILL_INSERT } from './dom';
import GenericProjectVariableWithinForm from './generic_project_variable_within_form';
import { DEFAULT_SELECT2_OPTS } from './js_select2';

export default function setupBehaviourActionsHandler() {
  setup();
  setListeners();
}

function setListeners() {
  $(document).on('turbolinks:load', function () {
    setup();
  });

  $(document).on(EVENT_WILL_INSERT, ({ detail }) => {
    setup(detail.html);
  });

  $(document).on('cocoon:before-insert', function (e, [wrapper]) {
    if (wrapper.classList.contains('nested-fields-behaviour-fields')) {
      new BehaviourWrapper(wrapper);
    }
  });
}

function setup(parent = document) {
  const BEHAVIOUR_SELECTOR = '.nested-fields-behaviour-fields';
  $(parent)
    .find(BEHAVIOUR_SELECTOR)
    .each(function () {
      new BehaviourWrapper(this);
    });
}

function BehaviourWrapper(wrapperEl) {
  this.wrapperEl = wrapperEl;
  this.$toByText = $(wrapperEl).find('.to-by-text');
  this.$actionSelect = $(wrapperEl).find('.behaviour-action-select');
  this.$variablesSelect = $(wrapperEl).find('.variables-js-select2');
  this.currentAction = this.$actionSelect.val();

  this.behaviourValueInput = new BehaviourValueInput(wrapperEl);

  this.listenForEvents();
  this.initializeInputType();
  this.updateToByText();
}

BehaviourWrapper.prototype.listenForEvents = function () {
  const self = this;

  self.$actionSelect.on('change', function (e) {
    self.currentAction = e.target.value;
    self.applySelect2();
    self.updateToByText();
    self.toggleTargetVariableWrapper();
  });

  self.$variablesSelect.on('select2:select', function (e) {
    self.valueInputTypeWillBe(e.params.data.value_type);
  });

  self.$actionSelect.trigger('change');
};

BehaviourWrapper.prototype.initializeInputType = function () {
  const currentVars = this.$variablesSelect.select2('data');
  const valueType = currentVars[0] ? currentVars[0].value_type : 'text';
  this.valueInputTypeWillBe(valueType);
};

BehaviourWrapper.prototype.toggleTargetVariableWrapper = function () {
  if (this.currentAction === 'add_up') {
    this.behaviourValueInput.disableTargetVarSelect(false); // Enable the Target Variable field for add_up action
  } else if (this.currentAction === 'toggle') {
    this.behaviourValueInput.hideValueInputForToggle(); // Hide the value fields for toggle action
  } else {
    this.behaviourValueInput.disableTargetVarSelect(true); // Enable the value fields for set and change action
  }
};

BehaviourWrapper.prototype.valueInputTypeWillBe = function (valueType) {
  this.behaviourValueInput.typeWillBe(valueType);
};

BehaviourWrapper.prototype.updateToByText = function () {
  if (this.currentAction === 'change') {
    this.$toByText.text('by');
  } else {
    this.$toByText.text('to');
  }
};

BehaviourWrapper.prototype.shouldDestroySelect2 = function () {
  if (this.$variablesSelect.hasClass('select2-hidden-accessible')) {
    this.$variablesSelect.html('');
    this.$variablesSelect.select2('destroy');
  }
};

/**
 * support applying or re-applying select2.
 * different options are required for select2 depending on
 * the behaviour's actionType.
 */
BehaviourWrapper.prototype.applySelect2 = function () {
  this.shouldDestroySelect2();

  const varsCollections = this.$variablesSelect.data('variables-collections');

  if (this.currentAction === 'set') {
    const select2Data = varsCollections.all_variables; // Show all variables for set
    this.initializeSelect2(select2Data);
  } else if (this.currentAction === 'toggle') {
    const select2Data = varsCollections.boolean_variables; // Show only boolean variables for toggle
    this.initializeSelect2(select2Data);
  } else {
    const select2Data = varsCollections.number_variables; // Show only number variables for add_up and change
    this.initializeSelect2(select2Data);
  }
  this.toggleTargetVariableWrapper();
};

// Function for handling select2Data
BehaviourWrapper.prototype.initializeSelect2 = function (select2Data) {
  const select2Opts = {
    ...DEFAULT_SELECT2_OPTS,
    data: select2Data,
  };

  if (['set', 'change', 'toggle'].includes(this.currentAction)) {
    select2Opts.maximumSelectionLength = 1;
  }

  this.$variablesSelect.select2(select2Opts);
};

function BehaviourValueInput(wrapperEl) {
  this.$textValueInputWrapper = $(wrapperEl).find('.non-boolean-value-input'); // Wrapper for text and number inputs
  this.$valueInputsWrapper = $(wrapperEl).find('.value-inputs-wrapper'); // Wrapper for boolean inputs
  this.$targetVarWrapper = $(wrapperEl).find('.target-variable-field'); // Wrapper for target variable field
  this.genericVarForm = new GenericProjectVariableWithinForm(wrapperEl);
}

BehaviourValueInput.prototype.typeWillBe = function (valueType) {
  const initMethodName = `handleType_${valueType}`;
  this[initMethodName]();
  this.genericVarForm.varValueTypeWillBe(valueType);
};

BehaviourValueInput.prototype.handleType_number = function () {
  this.setValueInputType('number');
};

BehaviourValueInput.prototype.disableTargetVarSelect = function (value) {
  if (value) {
    this.genericVarForm.enableInputsFor(this.$valueInputsWrapper);
    this.genericVarForm.disableInputsFor(this.$targetVarWrapper);
  } else {
    this.genericVarForm.enableInputsFor(this.$targetVarWrapper);
    this.genericVarForm.disableInputsFor(this.$valueInputsWrapper);
  }
};

BehaviourValueInput.prototype.handleType_list = function () {
  this.handleType_text();
};

BehaviourValueInput.prototype.handleType_text = function () {
  this.setValueInputType('text');
};

BehaviourValueInput.prototype.handleType_boolean = function () {};

BehaviourValueInput.prototype.setValueInputType = function (type) {
  const $valueInput = this.$textValueInputWrapper.find('input');
  $valueInput.attr('type', type);
};

BehaviourValueInput.prototype.hideValueInputForToggle = function () {
  // Remove the boolean input field to avoid sending any value with the form (Boolean variable value otherwise sends true or false)
  this.$valueInputsWrapper.find('.boolean-value-select').val('').hide().remove();

  // Remove the non-boolean value input field
  this.$textValueInputWrapper.find('input').hide().remove();

  // Remove the '.to-by-text' label and the value label
  const $valueInputsWrapper = this.$textValueInputWrapper.closest('.value-inputs-wrapper');
  $valueInputsWrapper.find('.to-by-text').hide().remove();
  $valueInputsWrapper.children('label').hide().remove();
};
