const inputSelector = '.condition--logical-statement--input-wrapper select';

export default function setupConditionGroupsUI(){
  const wrapperSelector = '.conditions-wrapper';

  $(document).on('change', inputSelector, function(e){
    const $wrapper = $(this).parents(wrapperSelector);
    $wrapper.find(inputSelector).val( e.target.value );
  });

  $(document).on('cocoon:after-insert', function (e, [conditionEl]) {
    const cocoonSelector = 'nested-fields-condition-fields';
    if (conditionEl.classList.contains(cocoonSelector)) {
      applyCurrentOperatorToNewCondition(conditionEl);
    }
  });
}

function applyCurrentOperatorToNewCondition(conditionEl) {
  const currentOperator =
    $(conditionEl.parentElement)
      .find(inputSelector)
      .first()
      .val();

  $(conditionEl).find(inputSelector).val(currentOperator);
}
