const CLASS_HIGHLIGHTED = 'wrp-highlighted';

export const SELECTOR_HIGHLIGHT_ISLAND = '.js-highlight-island';
export const SELECTOR_HIGHLIGHTED_ISLAND = '.' + CLASS_HIGHLIGHTED;

export const EVENT_WILL_POPULATE = 'studio:unselect-all';

const HIGHLIGHT_OPTIONS = {
  highlighter: {
    name: 'addClass',
    options: {
      className: CLASS_HIGHLIGHTED
    }
  }
};

const highlightedViews = [];

export default function setupHighlightIsland(paper) {
  // Bind to the CustomEvent so we detect properly
  // whether it was prevented or not and react accordingly
  // Handle "regular" clicks originating from keyboard interactions too
  $(document).on(
    'click studio:cell:pointerclick',
    SELECTOR_HIGHLIGHT_ISLAND,
    handleCellPointerClick
  );
  // Tidy up as we are using some global listeners
  $(document).on('turbolinks:before-cache', teardown);

  paper.model.on('add', cell => {
    if (cell.isElement()) {
      // Skip a frame to let the clearing from the sidebar closing
      // happen
      setTimeout(() => {
        const view = paper.findViewByModel(cell);
        highlightView(view);
      }, 0);
    }
  });

  /**
   * Highlight views if event wasn't prevented
   * @param {CustomEvent} event
   */
  function handleCellPointerClick(event) {
    if (!event.defaultPrevented) {
      const view = paper.findView(event.target);
      highlightView(view);
    }
  }

  /**
   * Do a little cleanup of the global listerners
   * when the page gets torn down
   */
  function teardown() {
    $(document).off('turbolinks:before-cache', teardown);
    $(document).off('studio:cell:pointerclick', handleCellPointerClick);
  }
}

/**
 * An island will only be highlighted if user has confirmed it in the prompt.
 * This is tracked using window.confirmedDrawerClose which becomes undefined each time this function runs.
 */
export function highlightView(cellView) {
  $(document).on(
    'drawer:cancel-close',
    '#studio-drawer',
    () => (window.confirmedDrawerClose = false)
  );
  const confirmed =
    typeof window.confirmedDrawerClose == 'undefined'
      ? true
      : window.confirmedDrawerClose;

  if (confirmed) {
    clearHighlightedViews();
    cellView.highlight(null, HIGHLIGHT_OPTIONS);
    highlightedViews.push(cellView);
  }

  window.confirmedDrawerClose = undefined;
}

export function isHighlighted(view) {
  return view.el.classList.contains(CLASS_HIGHLIGHTED);
}

function clearHighlightedViews() {
  if (highlightedViews.length) {
    // Cleans up highlighted parent class in order to allow content to be loaded on a new island.
    const highlightedIslands = document.querySelectorAll(
      `${SELECTOR_HIGHLIGHT_ISLAND}${SELECTOR_HIGHLIGHTED_ISLAND}`
    );
    highlightedIslands.forEach(island => {
      island.classList.remove(
        SELECTOR_HIGHLIGHTED_ISLAND.slice(1, SELECTOR_HIGHLIGHTED_ISLAND.length)
      );
    });

    // Removes highlight of the cellView itself.
    highlightedViews.forEach(cellView =>
      cellView.unhighlight(null, HIGHLIGHT_OPTIONS)
    );
    highlightedViews.splice(0, -1);
  }
}
