/**
 * Rendering of the UJS errors after submitting the form in the sidebar
 * Allows the server to send back a 422 error with a pre-rendered form
 * ready to replace the existing form, or JSON with a `flash.error` field
 * @module lib/ujs_form_errors
 */
import { replace } from './dom';
import { ERROR, NOTIFICATION, notify } from './notifications';

/** Container for the form errors */
const SELECTOR_ERRORS = '.js-form-errors';

export default function setupUjsFormErrors() {
  $(document).on('ajax:error', (event) => {
    event.preventDefault();
    const [response, , xhr] = event.detail;
    if (!xhr._wasAborted) {
      // If the status is 422, we expect the server to have sent
      // us back the updated form with errors
      if (xhr.status == 422 && event.target instanceof HTMLFormElement) {
        renderHTMLError(response, event.target);
      } else {
        // Otherwise we need to render our own error
        renderOtherErrors(response, event.target);
      }
    }
  });
}

function renderHTMLError(response, target) {
  const html = response.body.children[0];
  // Before replacing check where the focus is
  const focus = target.contains(document.activeElement);
  // When focus is outside the target, set a role="alert"
  // to trigger the vocalisation when the element get replaced
  if (!focus) {
    html.querySelector(SELECTOR_ERRORS).setAttribute('role', 'alert');
  }
  replace(html, target);
  // If focus is within the element, focus the errors
  if (focus) {
    const errors = html.querySelector(SELECTOR_ERRORS);
    if (errors) {
      errors.setAttribute('tabindex', -1);
      errors.focus();
      errors.setAttribute('role="alert"');
    } else {
      html.setAttribute('tabindex', -1);
      html.focus();
    }
  }
}

function renderOtherErrors(response, target) {
  if (target instanceof HTMLFormElement) {
    const errorContainer = target.querySelector(SELECTOR_ERRORS);
    if (errorContainer) {
      $(errorContainer).html(`
        <div class="alert alert-danger mx-n3">
          <p class="h3">Sorry, we could not save your data!</p>
        </div>
      `);
      errorContainer.setAttribute('tabindex', -1);
      errorContainer.focus();
    }
  } else if (response.flash && response.flash.error) {
    notify(response.flash.error, ERROR);
  } else if (response.flash && response.flash.notification) {
    notify(response.flash.notification, NOTIFICATION);
  } else {
    notify('Sorry, an error occured.', ERROR);
  }
}
