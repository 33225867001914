const SELECTOR = '.js-video';
export default function setupVideo() {
  const el = document.querySelector(SELECTOR);
  if (el && !el.hasAttribute('data-player-setup')) {
    el.setAttribute('data-player-setup', '');
    import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "videoPlayer" */
      './video'
    )
      .then(({ default: setupPlayer }) => {
        return setupPlayer(el);
      })
      .catch(e => {
        throw e;
      });
  }
}
