import { EVENT_WILL_INSERT } from './dom';
import GenericProjectVariableWithinForm from './generic_project_variable_within_form';

// We need 3 different handlers for different types of objects that are inserted by cocoon methods, otherwise the script doesn't find the value fields nested many layers down.
const CONDITION_NESTED_FIELDS_CLASSNAME1 = 'nested-fields-condition-fields'; // when a new condition field is added by cocoon
const CONDITION_NESTED_FIELDS_CLASSNAME2 = 'nested-fields-condition-block-fields'; // when a new condition block is added by cocoon
const CONDITION_NESTED_FIELDS_CLASSNAME3 = 'js-choice-tabs__tabpanel'; // when a new choice tab is added by cocoon

export default function setupConditionVariableTypesHandler() {
  $(document).on(EVENT_WILL_INSERT, ({ detail }) => {
    setup(detail.html);
  });

  $(document).on('cocoon:before-insert', function (e, [wrapper]) {
    if (wrapper.classList.contains(CONDITION_NESTED_FIELDS_CLASSNAME1) || wrapper.classList.contains(CONDITION_NESTED_FIELDS_CLASSNAME2)) {
      new ConditionWrapper(wrapper);
    } else if (wrapper.classList.contains(CONDITION_NESTED_FIELDS_CLASSNAME3)) {
      // When a new choice tab is added by cocoon, the script won't see nested-fields-condition-fields so we need to find it inside the tab
      const nestedConditionFields = $(wrapper).find(`.${CONDITION_NESTED_FIELDS_CLASSNAME1}`);
      if (nestedConditionFields.length) {
        nestedConditionFields.each(function () {
          new ConditionWrapper(this);
        });
      }
    }
  });
}

function setup(parent = document) {
  $(parent)
    .find(`.${CONDITION_NESTED_FIELDS_CLASSNAME1}`)
    .each(function () {
      new ConditionWrapper(this);
    });
}

function ConditionWrapper(wrapperEl) {
  const $wrapper = $(wrapperEl);
  const variableWrapperSelector = '.condition-fields-variable-wrapper';

  this.varsCollection = $wrapper.find(variableWrapperSelector).data('all-variables-collection');
  this.$nonBoolValueInput = $wrapper.find('.non-boolean-value-input');
  this.$operatorWrapper = $wrapper.find('.condition-fields-operator-wrapper');
  this.$variableSelect = $wrapper.find(`${variableWrapperSelector} select`);
  this.genericVariableTypeForm = new GenericProjectVariableWithinForm(wrapperEl);

  this.setVariableListener();
}

ConditionWrapper.prototype.setVariableListener = function () {
  const self = this;
  this.$variableSelect.on('change', function (e) {
    // if we removed blank option from the select we could
    // skip this
    if (!e.target.value) {
      return;
    }

    const selectedVar = self.varsCollection.find(function (varData) {
      return varData.id == e.target.value;
    });
    self.handleEnabledOperatorsFor(selectedVar.value_type);
    self.handleInputValueTypeFor(selectedVar.value_type);
    self.genericVariableTypeForm.varValueTypeWillBe(selectedVar.value_type);
  });
  this.$variableSelect.trigger('change');
};

ConditionWrapper.prototype.handleInputValueTypeFor = function (valueType) {
  if (valueType == 'number') {
    this.$nonBoolValueInput.find('input').attr('type', 'number');
  } else {
    this.$nonBoolValueInput.find('input').attr('type', 'text');
  }
};

ConditionWrapper.prototype.handleEnabledOperatorsFor = function (valueType) {
  const disabledOperators = valueType == 'number' ? [] : ['<', '>'];

  this.$operatorWrapper.find('select option').each(function () {
    if (disabledOperators.includes(this.value)) {
      this.setAttribute('disabled', 'disabled');
    } else {
      this.removeAttribute('disabled');
    }
  });

  if (valueType != 'number') {
    this.$operatorWrapper.find('select').val('==');
  }
};
