/**
 * Light DOM manipulation functions that notify when an event is inserted
 * @module lib/dom
 */

export const EVENT_WILL_INSERT = 'dom:will-insert';
export const EVENT_DID_INSERT = 'dom:did-insert';

/**
 * Replaces `oldNode` with `newNode`, emiting a bubbling `dom:will-insert`
 * event and a `dom:did-insert` event respectively before and after the
 * replacement happens
 * Signature matches the Node.replaceChild signature (https://developer.mozilla.org/en-US/docs/Web/API/Node/replaceChild)
 * @param {*} newNode
 * @param {*} oldNode
 */
export function replace(newNode, oldNode) {
  const event = new CustomEvent(EVENT_WILL_INSERT, {
    bubbles: true,
    detail: { html: newNode }
  });
  oldNode.dispatchEvent(event);
  const result = $(oldNode).replaceWith(newNode);
  newNode.dispatchEvent(
    new CustomEvent(EVENT_DID_INSERT, {
      bubbles: true
    })
  );
  return result;
}

/**
 * Replaces the content of the `parent` node with the `newNode`,
 * emitting a `dom:will-insert` and `dom:did-insert` event
 * respectively before and after the replacement happens
 * @param {*} newNode
 * @param {*} parent
 */
export function replaceContent(newNode, parent) {
  const event = new CustomEvent(EVENT_WILL_INSERT, {
    bubbles: true,
    detail: { html: newNode }
  });
  parent.dispatchEvent(event);
  const result = $(parent)
    .empty()
    .append(newNode);
  newNode.dispatchEvent(
    new CustomEvent(EVENT_DID_INSERT, {
      bubbles: true
    })
  );
  return result;
}
